import React, { useEffect } from 'react';
import { filterOption } from '@helpers/utils';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@helpers/use-search';
import { Card, Row, Col, Select, Input } from 'antd';
import { SearchButton } from '@components/SearchButton';
import { useSearchContext } from '@contexts/SearchProvider';
import { CustomService } from '@core/services/custom.service';
import { Wrapper, Label } from 'pages/draft-report/DraftReportSearch';
import { ReportTemplateSearchOptions } from '@core/@models/ReportTemplateModel';

export interface searchFields {
  name: string;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | ((value: string) => void);
  value?: string;
  options?: { label: string; value: string }[];
}

export const ReportTemplateSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { searchOptions, setSearchOptions } = useSearchContext();
  const {
    name,
    belongTo,
    handleReset,
    handleSearch,
    handleNameChange,
    handleBelongToChange,
  } = useSearch();

  useEffect(() => {
    getConfigForm();
  }, []);

  const getConfigForm = () => {
    CustomService.getData<ReportTemplateSearchOptions>(
      `/broker-templates/search-form`
    ).subscribe({
      next: (result: ReportTemplateSearchOptions) => {
        initialConfigData(result);
      },
    });
  };

  const initialConfigData = (result: ReportTemplateSearchOptions) => {
    const { belongTos: bt } = result;
    const options = bt?.map((b) => ({ value: b, label: b }));
    setSearchOptions({ belongTos: options });
  };

  const searchFields: searchFields[] = [
    {
      name: 'name',
      onChange: handleNameChange,
      value: name,
    },
    {
      name: 'belongTo',
      onChange: handleBelongToChange,
      value: belongTo,
      options: searchOptions?.belongTos,
    },
  ];

  const getLabel = (label: string) => <Label>{t(`${label}`)}</Label>;

  const getDropdownSearch = (props: searchFields) => (
    <Col flex="100" key={props.name}>
      <Wrapper>
        {getLabel(props.name)}
        <Select
          data-testid={`${props.name}-search-select`}
          allowClear
          showSearch
          options={props.options}
          style={{ width: '100%' }}
          filterOption={filterOption}
          dropdownMatchSelectWidth={false}
          value={props.value || undefined}
          placeholder={`${t('selectAll')}...`}
          onChange={props.onChange as (value: string) => void}
        />
      </Wrapper>
    </Col>
  );

  const getInputSearch = (props: searchFields) => (
    <Col flex="100" key={props.name}>
      <Wrapper>
        {getLabel(props.name)}
        <Input
          data-testid={`${props.name}-search-input`}
          allowClear
          value={props.value}
          onChange={handleNameChange}
          placeholder={`${t(`common:${props.name}`)}...`}
        />
      </Wrapper>
    </Col>
  );

  return (
    <Card
      title={t('filter')}
      className="search-form"
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row>
        {searchFields.map((s) =>
          s.name === 'name' ? getInputSearch(s) : getDropdownSearch(s)
        )}
      </Row>
    </Card>
  );
};
