import dayjs from 'dayjs';
import moment from 'moment';
import FileSaver from 'file-saver';
import styled from 'styled-components';
import { finalize } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import { ButtonSet } from '@components/ButtonSet';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { Uploader } from 'pages/draft-report/Uploader';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ApiService } from '@core/services/api.service';
import { useSearchContext } from '@contexts/SearchProvider';
import { CustomService } from '@core/services/custom.service';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Form,
  DatePicker,
  Space,
  Input,
  Popover,
  Select,
  Button,
  Spin,
} from 'antd';
import {
  BelongTo,
  FormField,
  ReportFormData,
  ReportTemplateFormConfigResponse,
  ReportTemplateFormProps,
  ReportTemplateFormResponse,
} from '@core/@models/ReportTemplateModel';
import { NotificationType, useNotification } from '@helpers/use-notification';

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 10 },
};

const dateFormat = 'DD/MM/YYYY';

const SETFormFields: FormField[] = [
  {
    label: 'Template Code',
    name: 'templateCode',
    fileTypes: [],
    maxLength: 20,
  },
  {
    label: 'Excel Template',
    name: 'xlsxTemplateFile',
    fileTypes: ['.XLSX'],
    require: false,
  },
  {
    label: 'Specification Template',
    name: 'specTemplateFile',
    fileTypes: ['.XML'],
  },
];

const SECFormFields: FormField[] = [
  { label: 'XML File', name: 'xmlFile', fileTypes: ['.ZIP'] },
  { label: 'XSL File', name: 'xslFile', fileTypes: ['.XSL'] },
  { label: 'XSL File (Thai)', name: 'xslThFile', fileTypes: ['.XSL'] },
  { label: 'XSL File (SEC)', name: 'xslSecFile', fileTypes: ['.XSL'] },
  { label: 'XSL File (SEC Thai)', name: 'xslSecThFile', fileTypes: ['.XSL'] },
];

export const ReportTemplateForm: React.FC<ReportTemplateFormProps> = ({
  ...props
}) => {
  const [form] = Form.useForm();
  const filesRef = useRef<File[]>([]);
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [data, setData] = useState<ReportTemplateFormResponse>();
  const [belongTo, setBelongTo] = useState<BelongTo>(BelongTo.SET);
  const editMode = !!props.templateId && !!props.id;
  const addVersionMode = !!props.templateId && !props.id;
  const { open } = useNotification();
  const apiService = useMemo(
    () =>
      new ApiService(
        addVersionMode ? '/broker-templates/new-version' : '/broker-templates'
      ),
    []
  );
  const {
    queryParam,
    reportStatus,
    searchOptions,
    handleQueryParam,
  } = useSearchContext();
  const isApproveTemplate = reportStatus.includes('approve');

  useEffect(() => {
    form.setFieldsValue({ belongTo: searchOptions?.belongTos[0].value });
    if (editMode) getDataById();
    else if (addVersionMode) getFormConfig();
  }, []);

  const getFormConfig = () => {
    setLoading(true);
    apiService
      .getDataById<string, ReportTemplateFormConfigResponse>(
        `${props.templateId}`
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: ReportTemplateFormConfigResponse) => {
          setBelongTo(response.belongTo);
          form.setFieldsValue({
            ...response,
            templateId: `${response.templateId}`,
          });
        },
      });
  };

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, ReportTemplateFormResponse>(`${props.id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: ReportTemplateFormResponse) => {
          setBelongTo(response.belongTo);
          setFormFieldsValue(response);
        },
      });
  };

  const setFormFieldsValue = (response: ReportTemplateFormResponse) => {
    setData(response);
    form.setFieldsValue({
      ...response,
      templateId: `${response.templateId}`,
      activeDate: moment(dayjs(response.activeDate).format('YYYY-MM-DD')),
      expireDate: moment(dayjs(response.expireDate).format('YYYY-MM-DD')),
    });
  };

  const onFinish = (reportFormData: ReportFormData) => {
    const requestData = transformData(reportFormData);
    setLoading(true);
    const action$ = apiService.createData(requestData);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        handleQueryParam(queryParam);
        if (props.forceFetchData) props.forceFetchData();
        props.handleCancel();
      },
    });
  };

  const transformData = (reportFormData: ReportFormData): FormData => {
    const formData = new FormData();
    const { activeDate, expireDate } = reportFormData;
    const blob = new Blob(
      [
        JSON.stringify({
          ...reportFormData,
          id: props.id,
          activeDate: dayjs(activeDate.toString()).format('YYYY-MM-DD'),
          expireDate: dayjs(expireDate.toString()).format('YYYY-MM-DD'),
        }),
      ],
      { type: 'application/json' }
    );
    formData.append('=data=', blob, 'data.json');
    filesRef.current.map((f, index) => {
      const name =
        reportFormData.belongTo === BelongTo.SET
          ? SETFormFields[index].name
          : SECFormFields[index].name;
      formData.append(name, f);
    });
    return formData;
  };

  const handleUpload = (file: File, index: number) =>
    (filesRef.current[index] = file);

  const handleDownload = (index: number) => {
    setFileLoading(true);
    const fields = belongTo === BelongTo.SET ? SETFormFields : SECFormFields;
    CustomService.downloadFile(
      `/broker-templates/${props.id}/download?fileKey=${fields[index].name}`
    )
      .pipe(finalize(() => setFileLoading(false)))
      .subscribe({
        next: (result: Blob) => {
          const blob = new Blob([result]);
          FileSaver.saveAs(blob, (data as any)[fields[index].name]);
        },
        error: () => {
          open({
            type: NotificationType.ERROR,
            description: t('common:downloadFailed'),
            disableDate: true,
          });
        },
      });
  };

  const getInitialFileList = (file: string | undefined) =>
    file ? [{ uid: file, name: file, status: 'done' }] : [];

  const uploadProps = {
    showRemoveIcon: false,
    showDownloadIcon: true,
    handleUpload: handleUpload,
    handleDownload: handleDownload,
    buttonProps: {
      style: { width: 150 },
      disabled:
        (editMode && !data?.canUpdate) || data?.approved || isApproveTemplate,
    },
  };

  const getAttachmentProps = (field: FormField) => {
    const require = field.require === undefined ? true : field.require;
    return {
      name: field.name,
      rules: [{ required: require, max: field.maxLength }],
      label: t(`${field.label}`) + (!require ? ` (${t('ifApplicable')})` : ''),
    };
  };

  return (
    <Spin spinning={fileLoading}>
      <Form
        {...layout}
        form={form}
        preserve={false}
        onFinish={onFinish}
        className="template-report-form"
        data-testid="template-report-form"
        onClick={(e) => e.stopPropagation()}
      >
        <Form.Item
          label={t('templateId')}
          name="templateId"
          rules={[{ required: true, max: 2 }]}
        >
          <Input
            data-testid="template-id-input"
            placeholder={t('templateId')}
            disabled={editMode || addVersionMode}
            style={{ width: 150 }}
          />
        </Form.Item>
        <Form.Item
          label={t('name')}
          name="name"
          rules={[{ required: true, max: 120 }]}
        >
          <Input
            data-testid="name-input"
            placeholder={t('name')}
            disabled={editMode || addVersionMode}
          />
        </Form.Item>
        <Form.Item
          label={t('activeDate')}
          name="activeDate"
          rules={[{ required: true }]}
        >
          <DatePicker
            data-testid="active-date-picker"
            allowClear={false}
            format={dateFormat}
            style={{ width: 270 }}
            disabled={editMode}
            placeholder={t('button.select')}
          />
        </Form.Item>
        <Form.Item
          label={t('expiryDate')}
          name="expireDate"
          rules={[{ required: true }]}
        >
          <DatePicker
            data-testid="expire-date-picker"
            allowClear={false}
            format={dateFormat}
            style={{ width: 270 }}
            disabled={(editMode && !data?.canUpdate) || isApproveTemplate}
            placeholder={t('button.select')}
          />
        </Form.Item>
        <Form.Item
          label={t('belongTo')}
          name="belongTo"
          rules={[{ required: true }]}
        >
          <Select
            data-testid="belong-to-select"
            options={searchOptions?.belongTos}
            disabled={editMode || addVersionMode}
            placeholder={t('belongTo')}
            onChange={(value) => setBelongTo(value as BelongTo)}
          />
        </Form.Item>
        <Form.Item
          label={t('autoApprove')}
          name="autoApprove"
          valuePropName="checked"
        >
          <Checkbox
            data-testid="auto-approve-checkbox"
            disabled={(editMode && !data?.canUpdate) || isApproveTemplate}
          />
        </Form.Item>
        {(belongTo === BelongTo.SET ? SETFormFields : SECFormFields).map(
          (f, i) => {
            const tempData: any = data;
            return (
              <div key={f.name}>
                {f.fileTypes.length !== 0 ? (
                  <Form.Item {...getAttachmentProps(f)}>
                    <Space align="baseline" style={{ width: '100%' }}>
                      <Uploader
                        index={i}
                        fileTypes={f.fileTypes}
                        initialFileList={getInitialFileList(
                          filesRef.current[i]
                            ? filesRef.current[i].name
                            : tempData
                            ? tempData[f.name]
                            : undefined
                        )}
                        {...uploadProps}
                      />
                      <Popover
                        style={{ marginTop: 7 }}
                        content={
                          <>
                            <Label>{t('supportFileTypes')}: </Label>
                            {f.fileTypes.toString()}
                          </>
                        }
                      >
                        <InfoCircleOutlined style={{ color: '#FCB034' }} />
                      </Popover>
                    </Space>
                  </Form.Item>
                ) : (
                  <Form.Item {...getAttachmentProps(f)}>
                    <Input
                      data-testid="template-code-input"
                      placeholder={f.label}
                      disabled={
                        (editMode && !data?.canUpdate) ||
                        data?.approved ||
                        isApproveTemplate
                      }
                      style={{ width: 150 }}
                    />
                  </Form.Item>
                )}
              </div>
            );
          }
        )}
        {isApproveTemplate || (data && !data.canUpdate) ? (
          <BtnWrapper>
            <Button
              ghost
              data-testid="close-report-template-form-button"
              type="primary"
              onClick={props.handleCancel}
              style={{ width: '80px' }}
            >
              {t('button.close')}
            </Button>
          </BtnWrapper>
        ) : (
          <ButtonSet handleCancel={props.handleCancel} loading={loading} />
        )}
      </Form>
    </Spin>
  );
};

const Label = styled.label`
  font-weight: bold;
`;

const BtnWrapper = styled(Space)`
  direction: 'vertical';
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;
