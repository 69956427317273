import { finalize } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import { ButtonSet } from '@components/ButtonSet';
import { ApiService } from '@core/services/api.service';
import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input } from 'antd';
import { Amphur, AmphurFormProps } from '@core/@models/ManageZoneModel';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

export const AmphurForm: React.FC<AmphurFormProps> = ({
  provinceCode,
  amphurCode,
  handleCancel,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Amphur>();
  const apiService = useMemo(
    () => new ApiService(`/zone/provinces/${provinceCode}/amphurs`),
    []
  );

  useEffect(() => {
    if (amphurCode) getDataById();
  }, []);

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, Amphur>(`${amphurCode}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (res: Amphur) => {
          form.setFieldsValue(res);
          setData(res);
        },
      });
  };

  const onFinish = (formData: Amphur) => {
    const requestData = { ...formData, id: amphurCode ? data?.id : undefined };
    setLoading(true);
    const action$ = amphurCode
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        fetchData && fetchData();
        handleCancel();
      },
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      preserve={false}
      onFinish={onFinish}
      data-testid="manage-amphur-form"
      onClick={(e) => e.stopPropagation()}
    >
      <Form.Item
        label={t('amphurCode')}
        name="code"
        rules={[{ required: true, max: 2 }]}
      >
        <Input
          data-testid="amphur-code-input"
          placeholder={t('amphurCode')}
          style={{ width: 150 }}
        />
      </Form.Item>
      <Form.Item
        label={t('amphurName')}
        name="name"
        rules={[{ required: true, max: 60 }]}
      >
        <Input data-testid="amphur-name-input" placeholder={t('amphurName')} />
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading} />
    </Form>
  );
};
