import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  SendOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { ModalCmp } from '@components/ModalCmp';
import { useSearchContext } from '@contexts/SearchProvider';
import { DataResponseBody } from '@core/@models/Types';
import {
  SentDraftReportReponseBody,
  ViewDraftReportResponseBody,
  ViewDraftReportSearchOptions,
} from '@core/@models/ViewDraftReportModel';
import { LanguageService } from '@core/services/language.service';
import { useBrokerReport } from '@helpers/use-broker-report';
import { useDataFetchingPagination } from '@helpers/use-data-fetching-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { convertToDateShort } from '@helpers/utils';
import { Button, Menu, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactComponent as ZipSvg } from 'assets/icon/zip.svg';
import { DropDownButton } from 'pages/draft-report/DropdownButton';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReportForm, ReportFormProps } from './ReportForm';

const { Item } = Menu;

export const ReportList = <
  T extends DataResponseBody
>(): React.ReactElement => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const { visible, hideModal, showModal } = useModalManagement();
  const {
    queryParam,
    reportStatus,
    menuId,
    roleId,
    searchOptions,
  } = useSearchContext();

  const {
    data,
    loading,
    pagination,
    handleTableChange,
    handleConfirmDeleteModal,
    handleConfirmModal,
  } = useDataFetchingPagination<T>(`broker-report/${reportStatus}/list`, {
    'x-menu-id': menuId,
    'x-role-id': roleId,
  });

  const {
    renderDownloadFile,
    renderStatus,
    renderShortDateTime,
    downloadFile,
    fileLoading,
  } = useBrokerReport();

  const isSentReport = 'dateType' in queryParam;

  const setCreateReportTitle = (templateName: string) => {
    setReportFormProps({ ...reportFormProps, templateName });
  };
  const [reportFormProps, setReportFormProps] = useState<ReportFormProps>({
    reportId: null,
    templateId: null,
    handleCancel: hideModal,
    setModalTitle: setCreateReportTitle,
  });

  const handleSelectRow = (record: DataResponseBody) => {
    const result = record as ViewDraftReportResponseBody;
    return {
      onClick: () => {
        if (isSentReport || !result.isEditable) return;
        onClickSelectRow(result);
      },
    };
  };

  const onClickSelectRow = (record: DataResponseBody) => {
    const result = record as ViewDraftReportResponseBody;
    const { id } = result;
    const rs = Object.assign({}, reportFormProps, {
      reportId: id,
      templateId: result.templateName,
    });
    setReportFormProps(rs);
    showModal();
  };

  const renderDraftActions = (
    value: string,
    record: DataResponseBody
  ): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      {downloadButton(value, record)}
      <Tooltip title={t('edit')}>
        <span
          style={{
            pointerEvents: (record as ViewDraftReportResponseBody).isEditable
              ? 'auto'
              : 'none',
          }}
        >
          <Button
            className="send"
            type="link"
            style={{ opacity: 1 }}
            icon={<EditOutlined />}
            size="small"
            disabled={!(record as ViewDraftReportResponseBody).isEditable}
            onClick={() => onClickSelectRow(record)}
          />
        </span>
      </Tooltip>
      <Tooltip title={t('button.send')}>
        <span
          style={{
            pointerEvents: (record as ViewDraftReportResponseBody).canSend
              ? 'auto'
              : 'none',
          }}
        >
          <Button
            className="send"
            type="link"
            style={{
              marginLeft: 5,
              opacity: 1,
              transform: 'rotate(-40deg)',
            }}
            icon={<SendOutlined />}
            size="small"
            disabled={!(record as ViewDraftReportResponseBody).canSend}
            onClick={() =>
              handleConfirmModal(
                `/broker-report/draft/${value}/sent`,
                `${t('button.send').toLowerCase()}`
              )
            }
          />
        </span>
      </Tooltip>
      <Tooltip title={t('button.delete')}>
        <span
          style={{
            pointerEvents: (record as ViewDraftReportResponseBody).isDelete
              ? 'auto'
              : 'none',
          }}
        >
          <Button
            className="send"
            type="link"
            style={{
              marginLeft: 2,
              opacity: 1,
            }}
            icon={<DeleteOutlined />}
            size="small"
            disabled={!(record as ViewDraftReportResponseBody).isDelete}
            onClick={() =>
              handleConfirmDeleteModal(`/broker-report/draft/${value}`)
            }
          />
        </span>
      </Tooltip>
    </div>
  );

  const downloadButton = (value: string, record: DataResponseBody) => {
    return (
      <Tooltip title={t('download')}>
        <span
          style={{
            pointerEvents: (record as ViewDraftReportResponseBody).isDownload
              ? 'auto'
              : 'none',
          }}
        >
          <Button
            className="send"
            type="link"
            style={{ opacity: 1 }}
            icon={<DownloadOutlined />}
            size="small"
            disabled={!(record as ViewDraftReportResponseBody).isDownload}
            onClick={() =>
              downloadFile(`/broker-report/download-file?reportId=${value}`)
            }
          />
        </span>
      </Tooltip>
    );
  };

  const downloadAttach = (
    value: string,
    record: SentDraftReportReponseBody
  ) => {
    return (
      <>
        <span>{value}</span>
        <Tooltip title={t('download attach file')}>
          <span
            style={{
              pointerEvents: record.isAttach ? 'auto' : 'none',
            }}
          >
            <Button
              className="send"
              type="link"
              style={{ opacity: 1 }}
              icon={<DownloadOutlined />}
              size="small"
              disabled={!record.isAttach}
              onClick={() =>
                downloadFile(
                  `/broker-report/download-file?fileName=attach&reportId=${record.id}`
                )
              }
            />
          </span>
        </Tooltip>
      </>
    );
  };

  const renderSentActions = (
    value: string,
    record: DataResponseBody
  ): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      {downloadButton(value, record)}
      <Tooltip title={t('button.copyToDraft')}>
        <Button
          type="link"
          style={{ opacity: 1 }}
          icon={<CopyOutlined />}
          size="small"
          disabled={!(record as SentDraftReportReponseBody).canCopy}
          onClick={() =>
            handleConfirmModal(
              `/broker-report/sent/${value}/copy`,
              `${t('button.copyToDraft').toLowerCase()} `
            )
          }
        />
      </Tooltip>
    </div>
  );

  const draftColumns: ColumnsType<T> = [
    {
      title: t('statusDate'),
      dataIndex: 'statusDate',
      key: 'statusDate',
      render: renderShortDateTime,
      sorter: true,
      defaultSortOrder: isSentReport ? undefined : 'descend',
      width: '12%',
    },
    {
      title: t('sender'),
      dataIndex: 'brokerCompanyName',
      key: 'company.code',
      sorter: true,
      width: '27%',
    },
    {
      title: t('name'),
      dataIndex: 'templateName',
      key: 'brokerReportTemplate.name',
      sorter: true,
      width: '15%',
      render: (value: string, record: DataResponseBody) =>
        downloadAttach(value, record as SentDraftReportReponseBody),
    },
    {
      title: t('dateAsOf'),
      dataIndex: 'periodDate',
      key: 'periodDate',
      sorter: true,
      render: (value: string) => convertToDateShort(value, lang),
      width: '10%',
    },
    {
      title: t('status'),
      dataIndex: 'statusName',
      key: 'status',
      sorter: true,
      render: renderStatus,
      width: '10%',
    },
    {
      title: 'Digest',
      dataIndex: 'id',
      align: 'center',
      width: '6%',
      render: (value: string, record: DataResponseBody) =>
        (record as ViewDraftReportResponseBody).isDigest &&
        renderDownloadFile(
          ZipSvg,
          `/broker-report/download-digest?reportId=${value}`,
          'download digest file'
        ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '10%',
      align: 'center',
      render: renderDraftActions,
      className: 'last-column',
    },
  ];
  const sentColumns: ColumnsType<T> = [
    {
      title: t('receivedDate'),
      dataIndex: 'receiveDate',
      key: 'receiveDate',
      render: renderShortDateTime,
      sorter: true,
      defaultSortOrder: 'descend',
      width: '12%',
    },
    {
      title: 'Action',
      render: renderSentActions,
      dataIndex: 'id',
      align: 'center',
      width: '10%',
      className: 'last-column',
    },
  ];

  const columns: ColumnsType<T> = useMemo(() => {
    if (isSentReport) {
      draftColumns.unshift(sentColumns[0]);
      draftColumns.pop();
      draftColumns.push(sentColumns[1]);
    }
    return draftColumns;
  }, [isSentReport, t]);

  const handleMenuClick = ({ key: tempalteId }: any) => {
    setReportFormProps({
      ...reportFormProps,
      reportId: null,
      templateId: tempalteId,
    });
    showModal();
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {(searchOptions as ViewDraftReportSearchOptions)?.templateNames.map(
        (s) => (
          <MenuItem key={s.value}>{s.label}</MenuItem>
        )
      )}
    </Menu>
  );

  return (
    <>
      {!isSentReport && <DropDownButton dropdownProps={{ overlay: menu }} />}
      <ModalCmp
        visible={visible}
        title={`${reportFormProps.templateName}`}
        width={800}
      >
        <ReportForm {...reportFormProps} />
      </ModalCmp>
      <Table
        size="small"
        loading={loading || fileLoading}
        pagination={pagination}
        dataSource={data}
        columns={columns}
        className={isSentReport ? 'unselectable-table' : 'selectable-table'}
        onRow={handleSelectRow}
        onChange={handleTableChange}
        sortDirections={['ascend', 'descend', 'ascend']}
        style={isSentReport ? { marginTop: 20 } : undefined}
        data-testid="report-table"
      />
    </>
  );
};

const MenuItem = styled(Item)`
  color: #231f20 !important;
  font-size: 13px;
  transition: background-color 0.3s;
  :hover {
    color: #fcb034 !important;
  }
`;
