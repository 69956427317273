import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib/button';
import { Button, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonAdd } from '@components/ButtonAdd';
import { ProvinceForm } from './ProvinceForm';
import { useDataFetching } from '@helpers/use-data-fetching';
import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { CustomService } from '@core/services/custom.service';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useModalManagement } from '@helpers/use-modal-management';
import {
  ProvinceFormProps,
  ManageZoneResponse,
  Region,
  Amphur,
} from '@core/@models/ManageZoneModel';
import { useSearchContext } from '@contexts/SearchProvider';
import { DataResponse } from '@core/@models/Types';
import { LanguageService } from '@core/services/language.service';

const actionStyle: ButtonProps = { type: 'link', size: 'small' };

export const ManageZoneList = (): React.ReactElement => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const { searchOptions, setSearchOptions } = useSearchContext();
  const { visible, hideModal, showModal } = useModalManagement();
  const [formProps, setFormProps] = useState<ProvinceFormProps>({
    handleCancel: hideModal,
  });
  const { data, loading, fetchData, onSearch } = useDataFetching(
    '/zone/provinces'
  );

  useEffect(() => {
    getRegions();
  }, [lang]);

  const getRegions = () => {
    CustomService.getData<Region[]>('/zone/regions').subscribe({
      next: (res: Region[]) => setSearchOptions(res),
    });
  };

  const getRegionView = (record: ManageZoneResponse): React.ReactElement => {
    const regions: Amphur[] = searchOptions || [];
    return <>{regions.filter((r) => r.id === record.regionId)[0]?.name}</>;
  };

  const getActionsView = (record: ManageZoneResponse): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Tooltip title={t('edit')}>
        <Button
          {...actionStyle}
          icon={<EditOutlined />}
          onClick={() => onClickRow(record)}
        />
      </Tooltip>
      <Tooltip title={t('button.delete')}>
        <Button
          {...actionStyle}
          style={{ marginLeft: 2 }}
          icon={<DeleteOutlined />}
          onClick={() => showConfirmModal(record.code)}
        />
      </Tooltip>
    </div>
  );

  const showConfirmModal = (provinceCode?: string) =>
    ConfirmModal({ onOk: () => onDelete(provinceCode) });

  const onDelete = (provinceCode?: string) => {
    CustomService.deleteData(`/zone/provinces/${provinceCode}`).subscribe({
      next: () => fetchData(),
    });
  };

  const columns: ColumnsType<ManageZoneResponse> = [
    {
      title: t('provinceCode'),
      dataIndex: 'code',
      width: '15%',
      sorter: {
        compare: (a, b) => a.code.localeCompare(b.code),
      },
    },
    {
      title: t('provinceName'),
      dataIndex: 'name',
      width: '35%',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: t('region'),
      render: getRegionView,
      width: '35%',
    },
    {
      title: 'Action',
      className: 'last-column',
      width: '15%',
      render: getActionsView,
      align: 'center',
    },
  ];

  const handleOnClickRow = (record: ManageZoneResponse) => {
    return {
      onClick: () => onClickRow(record),
    };
  };

  const onClickRow = (record: ManageZoneResponse) => {
    setFormProps({ ...formProps, provinceCode: record.code });
    showModal();
  };

  const onClickAdd = () => {
    setFormProps({ ...formProps, provinceCode: undefined });
    showModal();
  };

  return (
    <>
      <ButtonAdd showModal={onClickAdd} />
      <Table
        size="small"
        data-testid="manage-zone-table"
        className="selectable-table"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        onRow={handleOnClickRow}
        columns={columns}
        dataSource={onSearch<DataResponse>(data, ['code', 'name'])}
        loading={loading}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
      <ModalCmp
        visible={visible}
        title={formProps.provinceCode ? t('edit') : t('button.add')}
        width={700}
      >
        <ProvinceForm {...formProps} />
      </ModalCmp>
    </>
  );
};
