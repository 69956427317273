import { backToSETPortal } from '@helpers/utils';
import React, { useState } from 'react';
import styled from 'styled-components';

export const HeaderLogo: React.FC = () => {
  const [SETLogo, setSETLogo] = useState();

  import(
    process.env.REACT_APP_ENV !== 'prod'
      ? `../assets/SET_Logo_${process.env.REACT_APP_ENV}.png`
      : '../assets/SET_Logo.png'
  ).then((module) => {
    setSETLogo(module.default);
  });

  return (
    <Logo>
      <img
        src={SETLogo}
        alt="set-logo"
        onClick={() => backToSETPortal(process.env.REACT_APP_ENV)}
      />
    </Logo>
  );
};
const Logo = styled.div`
  height: 48px;
  max-width: 256px;
  min-width: 256px;
  width: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    cursor: pointer;
    width: 158px;
  }
`;
