import instance from '@core/axios';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export class ApiService {
  url: string;
  constructor(url: string) {
    this.url = url;
  }
  public getData<T>(params?: any): Observable<T[]> {
    return from(
      instance.get<T[]>(this.url, Object.assign({}, this.getParam(params)))
    ).pipe(map((d) => d.data));
  }

  private getParam(params: any): { params: any } {
    if (params) {
      return {
        params,
      };
    }
    return params;
  }

  public getDataPagable<T>(params?: any, headers?: any): Observable<T> {
    return from(
      instance.get<T>(
        this.url,
        Object.assign({}, this.getParam(params), { headers: headers })
      )
    ).pipe(map((d) => d.data));
  }

  public getDataById<T, R>(id: T): Observable<R> {
    const url = `${this.url}/${id}`;
    return from(instance.get<R>(url)).pipe(map((d) => d.data));
  }

  public deleteData<T>(id: T): Observable<null> {
    const url = `${this.url}/${id}`;
    return from(instance.delete<null>(url)).pipe(map((d) => d.data));
  }

  public updateData<T, R>(data: T): Observable<R> {
    return from(instance.put<R>(this.url, data)).pipe(map((d) => d.data));
  }

  public createData<T, R>(data: T): Observable<R> {
    return from(instance.post<R>(this.url, data)).pipe(map((d) => d.data));
  }
}
