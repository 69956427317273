import { SearchProvider } from '@contexts/SearchProvider';

import { SortDirection } from '@core/@models/PaginationModal';
import {
  ViewDraftReportSearchOptions,
  ViewDraftReportQueryParam,
  ViewDraftReportResponseBody,
} from '@core/@models/ViewDraftReportModel';
import * as React from 'react';
import { ReportList } from './ReportList';
import { ReportSearch } from './ReportSearch';

export const ViewDraftReport: React.FC = () => {
  const initialParam: ViewDraftReportQueryParam = {
    templateId: undefined,
    statusId: null,
    sort: `statusDate,${SortDirection.DESC}`,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<ViewDraftReportQueryParam, ViewDraftReportSearchOptions>
      initialParam={initialParam}
    >
      <ReportSearch />
      <ReportList<ViewDraftReportResponseBody> />
    </SearchProvider>
  );
};
