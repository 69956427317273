import { FooterPage } from '@components/FooterPage';
import { AuthProvider } from '@contexts/AuthProvider';
import { LanguageService } from '@core/services/language.service';
import { ConfigProvider, Layout } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Headers } from './Headers';
import { SubHeader } from './SubHeader';
import { SiderMenuCollapse } from './SideMenuCollapse';
const { Content } = Layout;

const langProps = {
  lang: {
    locale: 'en_US',
    placeholder: 'Select date',
    rangePlaceholder: ['Start date', 'End date'],
    today: 'Today',
    now: 'Now',
    backToToday: 'Back to today',
    ok: 'Ok',
    clear: 'Clear',
    month: 'Month',
    year: 'Year',
    timeSelect: 'Select time',
    dateSelect: 'Select date',
    monthSelect: 'Choose a month',
    yearSelect: 'Choose a year',
    decadeSelect: 'Choose a decade',
    yearFormat: 'YYYY',
    dateFormat: 'M/D/YYYY',
    dayFormat: 'D',
    dateTimeFormat: 'M/D/YYYY HH:mm:ss',
    monthFormat: 'MMMM',
    monthBeforeYear: true,
    previousMonth: 'Previous month (PageUp)',
    nextMonth: 'Next month (PageDown)',
    previousYear: 'Last year (Control + left)',
    nextYear: 'Next year (Control + right)',
    previousDecade: 'Last decade',
    nextDecade: 'Next decade',
    previousCentury: 'Last century',
    nextCentury: 'Next century',
  },
  timePickerLocale: {
    placeholder: 'Select time',
  },
  dateFormat: 'DD/MM/YYYY',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  weekFormat: 'YYYY-wo',
  monthFormat: 'MMMM',
};
export const configAntProvider: { [key: string]: any } = {
  th: {
    lang: Object.assign({}, langProps, {
      locale: 'th_TH',
      monthSelect: 'เลือกเดือน',
      yearSelect: 'เลือกปี',
    }),
  },
  en: langProps,
};

export const DefaultLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const lang = LanguageService.getLanguage();

  const validateMessages = {
    whitespace: t('common:errorMessage.required', {
      labelName: '${label}',
    }),
    required: t('common:errorMessage.required', {
      labelName: '${label}',
    }),
    string: {
      max: t('common:errorMessage.maxLength', {
        labelName: '${label}',
        max: '${max}',
      }),
    },
    types: {
      url: t('common:errorMessage.url', {
        labelName: '${label}',
      }),
    },
  };
  return (
    <AuthProvider>
      <ConfigProvider
        form={{ validateMessages }}
        locale={configAntProvider[lang]}
      >
        <Layout style={{ minHeight: '100vh' }} id="default-layout">
          <Headers />
          <Layout className="site-layout">
            <SiderMenuCollapse />
            <Layout style={{ padding: '0 24px 0 0' }}>
              <SubHeader />
              <Content
                className="site-layout-background"
                style={{ margin: '10px 16px 0', minHeight: 200 }}
              >
                {children}
              </Content>
              <FooterPage />
            </Layout>
          </Layout>
        </Layout>
      </ConfigProvider>
    </AuthProvider>
  );
};
