import { finalize } from 'rxjs/operators';
import { ColumnsType } from 'antd/lib/table';
import { ButtonProps } from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
import { ButtonSet } from '@components/ButtonSet';
import { AmphurForm } from './AmphurForm';
import { ApiService } from '@core/services/api.service';
import { useSearchContext } from '@contexts/SearchProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { CustomService } from '@core/services/custom.service';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { LanguageService } from '@core/services/language.service';
import { useModalManagement } from '@helpers/use-modal-management';
import { Button, Form, Input, Select, Table, Tooltip } from 'antd';
import {
  Region,
  Amphur,
  AmphurFormProps,
  ProvinceFormProps,
  ProvinceResponse,
  ManageZoneResponse,
} from '@core/@models/ManageZoneModel';

const actionStyle: ButtonProps = { type: 'link', size: 'small' };
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

export const ProvinceForm: React.FC<ProvinceFormProps> = ({
  provinceCode,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProvinceResponse>();
  const { handleQueryParam } = useSearchContext();
  const apiService = useMemo(() => new ApiService('zone/provinces'), []);
  const { visible, showModal, hideModal } = useModalManagement();
  const { searchOptions } = useSearchContext();
  const [formProps, setFormProps] = useState<AmphurFormProps>({
    provinceCode,
    handleCancel: hideModal,
  });

  useEffect(() => {
    if (provinceCode) getDataById();
    else form.setFieldsValue({ regionId: 1 });
  }, []);

  const getRegionsOptions = () => {
    return (searchOptions as Region[]).map((s) => {
      return { label: s.name, value: s.id };
    });
  };

  const getDataById = () => {
    setLoading(true);
    CustomService.getDataById<ProvinceResponse>(
      `/zone/provinces/${provinceCode}/detail`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (res: ProvinceResponse) => {
          form.setFieldsValue(res);
          setData(res);
        },
      });
  };

  const fetchAmphurs = () => {
    setLoading(true);
    CustomService.getDataById<ProvinceResponse>(
      `/zone/provinces/${provinceCode}/detail`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (res: ProvinceResponse) => setData(res),
      });
  };

  const onFinish = (formData: ManageZoneResponse) => {
    const requestData = {
      ...formData,
      id: provinceCode ? data?.id : undefined,
    };
    setLoading(true);
    const action$ = provinceCode
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        handleQueryParam({});
        handleCancel();
      },
    });
  };

  const getActionsView = (record: ManageZoneResponse): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Tooltip title={t('edit')}>
        <Button
          {...actionStyle}
          icon={<EditOutlined />}
          onClick={() => onClickRow(record)}
        />
      </Tooltip>
      <Tooltip title={t('button.delete')}>
        <Button
          {...actionStyle}
          style={{ marginLeft: 2 }}
          icon={<DeleteOutlined />}
          onClick={() => showConfirmModal(record.code)}
        />
      </Tooltip>
    </div>
  );

  const showConfirmModal = (amphurCode?: string) =>
    ConfirmModal({ onOk: () => onDelete(amphurCode) });

  const onDelete = (amphurCode?: string) => {
    CustomService.deleteData(
      `/zone/provinces/${provinceCode}/amphurs/${amphurCode}`
    ).subscribe({
      next: () => getDataById(),
    });
  };

  const columns: ColumnsType<Amphur> = [
    {
      title: t('amphurCode'),
      dataIndex: 'code',
      width: '20%',
    },
    {
      title: t('amphurName'),
      dataIndex: 'name',
      width: '65%',
    },
    {
      title: 'Action',
      className: 'last-column',
      width: '15%',
      render: getActionsView,
      align: 'center',
    },
  ];

  const handleOnClickRow = (record: Amphur) => {
    return { onClick: () => onClickRow(record) };
  };

  const onClickRow = (record: Amphur) => {
    setFormProps({ ...formProps, amphurCode: record.code });
    showModal();
  };

  const onClickAdd = () => {
    setFormProps({ ...formProps, amphurCode: undefined });
    showModal();
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        preserve={false}
        onFinish={onFinish}
        data-testid="manage-zone-form"
        onClick={(e) => e.stopPropagation()}
      >
        <Form.Item
          label={t('provinceCode')}
          name="code"
          rules={[{ required: true, max: 3 }]}
        >
          <Input
            data-testid="province-code-input"
            placeholder={t('provinceCode')}
            style={{ width: 150 }}
          />
        </Form.Item>
        <Form.Item
          label={t('provinceName')}
          name="name"
          rules={[{ required: true, max: 60 }]}
        >
          <Input
            data-testid="province-name-input"
            placeholder={t('provinceName')}
          />
        </Form.Item>
        <Form.Item
          label={t('region')}
          name="regionId"
          rules={[{ required: true }]}
        >
          <Select
            data-testid="region-dropdown"
            placeholder={t('region')}
            options={getRegionsOptions()}
          />
        </Form.Item>
        {provinceCode && (
          <div style={{ textAlign: 'center' }}>
            <Button
              data-testid="add-amphur-button"
              type="primary"
              onClick={() => onClickAdd()}
            >
              {`${t('button.add')}${lang === 'en' ? ' ' : ''}${t('amphur')}`}
            </Button>
            <Table
              size="small"
              data-testid="amphur-table"
              className="selectable-table"
              style={{ marginBottom: 24, marginTop: 24 }}
              pagination={{
                pageSize: 15,
                showSizeChanger: false,
                showTotal: (total) => t('totalItem', { total }),
              }}
              onRow={handleOnClickRow}
              columns={columns}
              dataSource={data?.amphurs}
              loading={loading}
            />
          </div>
        )}
        <ButtonSet handleCancel={handleCancel} loading={loading} />
      </Form>
      <ModalCmp
        visible={visible}
        title={formProps.amphurCode ? t('edit') : t('button.add')}
        width={700}
      >
        <AmphurForm {...formProps} fetchData={() => fetchAmphurs()} />
      </ModalCmp>
    </>
  );
};
