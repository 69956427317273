import { ConfirmModal } from '@components/ModalCmp';
import { useSearchContext } from '@contexts/SearchProvider';
import { PagableResponse } from '@core/@models/PaginationModal';
import { DataResponseBody } from '@core/@models/Types';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';

type CustomSorter = {
  field: React.Key;
  order: SortOrder;
};
export interface FetchingDataPagination<T extends DataResponseBody> {
  data: T[];
  loading: boolean;
  pagination: TablePaginationConfig;
  handleTableChange: (
    tablePagination: TablePaginationConfig,
    _: Record<string, React.Key[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[]
  ) => void;
  fetchData: () => void;
  handleConfirmDeleteModal: (url: string) => void;
  handleConfirmModal: (url: string, contentName: string) => void;
}

export const useDataFetchingPagination = <T extends DataResponseBody>(
  url: string,
  headers?: { [key: string]: string | null }
): FetchingDataPagination<T> => {
  const apiService = React.useMemo(() => new ApiService(url), [url]);
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(['common']);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    showSizeChanger: false,
    showTotal: (total) => t('common:totalItem', { total }),
  });

  const { queryParam, handleQueryParam } = useSearchContext();

  useEffect(() => {
    fetchData();
  }, [queryParam, t]);

  const getSortDescription = (sorter: CustomSorter): string => {
    const { field, order } = sorter;
    const abbrSortType = order?.substr(0, order.length - 3);
    return `${field},${abbrSortType}`;
  };

  const handleTableChange = (
    tablePagination: TablePaginationConfig,
    _: Record<string, React.Key[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[]
  ) => {
    if (Array.isArray(sorter)) return;
    const [field] = queryParam.sort.split(',');
    const { columnKey = field, order = 'descend' } = sorter;
    const customSorter: CustomSorter = {
      field: columnKey,
      order,
    };
    const sort = getSortDescription(customSorter);
    const { current: page, pageSize: size } = tablePagination;
    const param = Object.assign({}, { ...queryParam, page, size, sort });
    handleQueryParam(param);
  };

  const fetchData = () => {
    setLoading(true);
    apiService
      .getDataPagable<PagableResponse<T>>(queryParam, headers)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: PagableResponse<T>) => {
          const { content: content, totalElements: total } = result;
          setData(content);
          const { page: current, size: pageSize } = queryParam;
          setPagination({ ...pagination, current, pageSize, total });
        },
      });
  };

  const handleConfirmDeleteModal = (apiUrl: string) => {
    ConfirmModal({ onOk: () => onDelete(apiUrl) });
  };

  const onDelete = (apiUrl: string) => {
    CustomService.deleteData(apiUrl).subscribe({
      next: () => fetchData(),
    });
  };

  const handleConfirmModal = (apiUrl: string, contentName: string) => {
    ConfirmModal({
      content: t('confirmContent', {
        contentName,
      }),
      onOk: () => {
        CustomService.createData(apiUrl, {}).subscribe({
          next: () => fetchData(),
        });
      },
    });
  };

  return {
    data,
    loading,
    pagination,
    handleTableChange,
    fetchData,
    handleConfirmDeleteModal,
    handleConfirmModal,
  };
};
