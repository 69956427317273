import React from 'react';
import { Search, TYPE } from '@components/Search';
import { ManageNativeList } from './ManageNativeList';
import { SearchProvider } from '@contexts/SearchProvider';

export const ManageNative: React.FC = () => {
  const fieldProperties = [
    {
      labelName: 'code',
      name: 'code',
      type: TYPE.INPUT,
    },
    {
      labelName: 'name',
      name: 'name',
      type: TYPE.INPUT,
    },
  ];

  return (
    <SearchProvider>
      <Search fieldProps={fieldProperties} />
      <ManageNativeList />
    </SearchProvider>
  );
};
