import moment from 'moment';
import styled from 'styled-components';
import { filterOption } from '@helpers/utils';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@helpers/use-search';
import React, { useEffect, useState } from 'react';
import { SearchButton } from '@components/SearchButton';
import { Card, Row, Col, Select, DatePicker } from 'antd';
import { useSearchContext } from '@contexts/SearchProvider';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { DraftReportConfigFormResponse } from '@core/@models/DraftReportModel';

const { RangePicker } = DatePicker;

export interface onRangePickerChange {
  onDateChange: (values: any, formatString: [string, string]) => void;
  onCalendarChange: (values: any, formatString: [string, string]) => void;
}
export interface searchFields {
  name: string;
  onChange: ((value: string) => void) | onRangePickerChange;
  value?: string;
  options?: { label: string; value: string }[];
  colFlex: string;
  maxWidth?: number;
}

export const DraftReportSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const {
    reportStatus,
    menuId,
    roleId,
    searchOptions,
    setSearchOptions,
  } = useSearchContext();
  const [showCompanies, setShowCompanies] = useState<boolean>(false);
  const {
    rangeDate,
    periodId,
    statusId,
    companyId,
    templateId,
    handleReset,
    handleSearch,
    handleRangeDateChange,
    handlePeriodIdChange,
    handleStatusIdChange,
    handleCalendarChange,
    handleCompanyIdChange,
    handleTemplateIdChange,
  } = useSearch();

  useEffect(() => {
    getConfigForm();
  }, [lang]);

  const getConfigForm = () => {
    CustomService.getData<DraftReportConfigFormResponse>(
      `broker-report-group/${reportStatus}/search-form`,
      undefined,
      { 'x-menu-id': menuId, 'x-role-id': roleId }
    ).subscribe({
      next: (result: DraftReportConfigFormResponse) => {
        setShowCompanies(result.brokerCompanies !== null);
        initialConfigData(result);
      },
    });
  };

  const initialConfigData = (result: DraftReportConfigFormResponse) => {
    const {
      brokerCompanies: bc,
      periods: pr,
      templateNames: tn,
      statuses: st,
    } = result;
    const brokerCompanies = bc?.map((b) => ({
      value: `${b.id}`,
      label: b.name,
    }));
    const templateNames = tn.map((t) => ({ value: `${t.id}`, label: t.name }));
    const periods = pr.map((p) => ({ value: `${p.id}`, label: p.name }));
    const statuses = st.map((s) => ({ value: s.id, label: s.name }));
    setSearchOptions({ brokerCompanies, templateNames, periods, statuses });
  };

  const getSearchFields = (): searchFields[][] => {
    const searchFields = [
      [
        {
          name: 'brokerName',
          onChange: handleCompanyIdChange,
          value: companyId,
          options: searchOptions?.brokerCompanies,
          colFlex: '40',
        },
        {
          name: 'reportName',
          onChange: handleTemplateIdChange,
          value: templateId,
          options: searchOptions?.templateNames,
          colFlex: '120',
          maxWidth: showCompanies ? 590 : 380,
        },
        {
          name: 'reportPeriod',
          onChange: handlePeriodIdChange,
          value: periodId,
          options: searchOptions?.periods,
          colFlex: '50',
        },
      ],
      [
        {
          name: 'period',
          onChange: {
            onDateChange: handleRangeDateChange,
            onCalendarChange: handleCalendarChange,
          },
          colFlex: showCompanies ? '70' : '100',
        },
        {
          name: 'status',
          onChange: handleStatusIdChange,
          value: statusId,
          options: searchOptions?.statuses,
          colFlex: '50',
        },
      ],
    ];
    if (!showCompanies) searchFields[0] = searchFields[0].slice(1, 3);
    return searchFields;
  };

  const getLabel = (name: string) => <Label>{t(`${name}`)}</Label>;

  const getDropdownSearch = (props: searchFields) => (
    <Col
      flex={props.colFlex}
      style={{ maxWidth: props.maxWidth }}
      key={props.name}
    >
      <Wrapper>
        {getLabel(props.name)}
        <Select
          data-testid={`${props.name}-search-dropdown`}
          allowClear
          showSearch
          options={props.options}
          style={{ width: '100%' }}
          filterOption={filterOption}
          dropdownMatchSelectWidth={false}
          value={props.value || undefined}
          placeholder={`${t('selectAll')}...`}
          onChange={props.onChange as (value: string) => void}
        ></Select>
      </Wrapper>
    </Col>
  );

  const getDateSearch = (props: searchFields) => (
    <Col flex={props.colFlex} key={props.name}>
      <Wrapper>
        {getLabel(props.name)}
        <RangePicker
          data-testid={`${props.name}-search-date-picker`}
          style={{ width: '100%' }}
          placeholder={[`${t('begin')}...`, `${t('end')}...`]}
          value={rangeDate}
          onChange={(props.onChange as onRangePickerChange).onDateChange}
          onCalendarChange={
            (props.onChange as onRangePickerChange).onCalendarChange
          }
          ranges={{
            Today: [moment(), moment()],
          }}
        />
      </Wrapper>
    </Col>
  );

  const searchFields = getSearchFields();

  return (
    <Card
      title={t('filter')}
      className="search-form"
      bodyStyle={{ padding: '0px 5px 10px' }}
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      {!showCompanies && (
        <Row>
          {searchFields.map((s1) =>
            s1.map((s2) =>
              s2.name === 'period' ? getDateSearch(s2) : getDropdownSearch(s2)
            )
          )}
        </Row>
      )}
      {showCompanies &&
        searchFields.map((s1, i) => (
          <Row key={i} style={{ marginBottom: i ? 0 : 10 }}>
            {s1.map((s2) =>
              s2.name === 'period' ? getDateSearch(s2) : getDropdownSearch(s2)
            )}
          </Row>
        ))}
    </Card>
  );
};

export const Wrapper = styled.div`
  display: 'flex';
  margin: 0px 5px;
`;

export const Label = styled.label`
  font-weight: 500;
`;
