import { Form, Input } from 'antd';
import { finalize } from 'rxjs/operators';
import { useTranslation } from 'react-i18next';
import { ButtonSet } from '@components/ButtonSet';
import { ApiService } from '@core/services/api.service';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ManageNativeFormProps,
  ManageNativeResponse,
} from '@core/@models/ManageNativeModel';
import { useSearchContext } from '@contexts/SearchProvider';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

export const ManageNativeForm: React.FC<ManageNativeFormProps> = ({
  id,
  handleCancel,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const apiService = useMemo(() => new ApiService('natives'), []);
  const { handleQueryParam } = useSearchContext();

  useEffect(() => {
    if (id) getDataById();
  }, []);

  const getDataById = () => {
    setLoading(true);
    apiService
      .getDataById<string, ManageNativeResponse>(`${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: ManageNativeResponse) => form.setFieldsValue(response),
      });
  };

  const onFinish = (formData: ManageNativeResponse) => {
    const requestData = { ...formData, id };
    setLoading(true);
    const action$ = id
      ? apiService.updateData(requestData)
      : apiService.createData(requestData);
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        handleQueryParam({});
        handleCancel();
      },
    });
  };

  return (
    <Form
      {...layout}
      form={form}
      preserve={false}
      onFinish={onFinish}
      data-testid="manage-native-form"
      onClick={(e) => e.stopPropagation()}
    >
      <Form.Item
        label={t('code')}
        name="code"
        rules={[{ required: true, max: 6 }]}
      >
        <Input
          data-testid="code-input"
          placeholder={t('code')}
          style={{ width: 150 }}
        />
      </Form.Item>
      <Form.Item
        label={t('name')}
        name="name"
        rules={[{ required: true, max: 32 }]}
      >
        <Input data-testid="name-input" placeholder={t('name')} />
      </Form.Item>
      <ButtonSet handleCancel={handleCancel} loading={loading} />
    </Form>
  );
};
