import { QueryParam, SearchOptions } from '@core/@models/Types';
import React from 'react';
import { PropsWithChildren, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface SearchContextProps<
  T extends QueryParam,
  U extends SearchOptions
> {
  queryParam: T;
  filter: any;
  reportStatus: string;
  menuId: string | null;
  roleId: string | null;
  handleQueryParam: (query: T) => void;
  handleFilter: (query: any) => void;
  searchOptions: U;
  setSearchOptions: (searchOptions: U) => void;
}

const SearchContext = React.createContext<
  SearchContextProps<any, any> | undefined
>(undefined);

const SearchProvider = <T extends QueryParam, U extends SearchOptions>({
  children,
  initialParam,
}: PropsWithChildren<{ initialParam?: T }>): React.ReactElement => {
  const location = useLocation<{ subMenuId: string }>();
  const reportStatus =
    location.pathname
      .split('/')
      .filter((v) => !!v)
      .pop() || '';
  const urlSearchParams = new URLSearchParams(location.search);
  const menuId = urlSearchParams.get('subMenuId');
  const roleId = urlSearchParams.get('roleId');
  const [queryParam, setQueryParam] = useState<T | undefined>(initialParam);
  const [filter, setFilter] = useState<any>();
  const [searchOptions, setSearchOptions] = useState<U | undefined>();

  const handleQueryParam = (query: T) => {
    setQueryParam({ ...queryParam, ...query });
  };

  const handleFilter = (query: any) => {
    setFilter({ ...filter, ...query });
  };

  const value = {
    queryParam,
    filter,
    reportStatus,
    handleQueryParam,
    handleFilter,
    menuId,
    roleId,
    searchOptions,
    setSearchOptions,
  };
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

const useSearchContext = () => {
  const searchContext = React.useContext(SearchContext);
  if (!searchContext) {
    throw new Error(
      'useSearchContext must be used within the SearchContext.Provider'
    );
  }
  return searchContext;
};

export { SearchProvider, useSearchContext };
