import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IButtonSet {
  handleCancel: () => void;
  loading?:
    | boolean
    | {
        delay?: number;
      };
  buttonProps?: ButtonProps;
  caption?: string;
  disabled?: boolean;
}
export const ButtonSet: React.FC<IButtonSet> = ({
  handleCancel,
  loading,
  ...props
}: IButtonSet) => {
  const { t } = useTranslation();
  return (
    <>
      <Button
        style={{ width: '80px' }}
        data-testid="close"
        type="primary"
        ghost
        htmlType="button"
        onClick={handleCancel}
        disabled={props.disabled}
      >
        {t('common:button.close')}
      </Button>
      <Button
        style={{ width: props.caption ? 'auto' : '80px', float: 'right' }}
        data-testid="save"
        type="primary"
        htmlType="submit"
        loading={loading}
        {...props.buttonProps}
      >
        {props.caption || t('common:button.save')}
      </Button>
    </>
  );
};
