import { ColumnsType } from 'antd/lib/table';
import { DataResponse } from './Types';

export interface ReportTemplateQueryParam {
  name: string | null;
  belongTo: number | undefined;
}

export interface ReportTemplateResponse {
  id: number;
  templateId: number;
  name: string;
  belongTo: BelongTo;
  activeDate: string;
  expireDate: string;
  approved: boolean;
  canDelete: boolean;
}

export interface ReportTemplateFormConfigResponse {
  templateId: number;
  name: string;
  belongTo: BelongTo;
  canUpdate: boolean;
}

export interface ReportTemplateVersionResponse {
  canNewVersion: boolean;
  templates: ReportTemplateResponse[];
}

export interface ReportTemplateFormResponse
  extends ReportTemplateResponse,
    SETAttachment,
    SECAttachment {
  autoApprove: boolean;
  canUpdate: boolean;
}

export interface SETAttachment {
  templateCode?: string;
  xlsxTemplateFile?: string;
  specTemplateFile?: string;
}

export interface SECAttachment {
  xmlFile?: string;
  xslFile?: string;
  xslThFile?: string;
  xslSecFile?: string;
  xslSecThFile?: string;
}

export interface ReportTemplateSearchOptions {
  belongTos: string[];
}

export interface ReportTemplateFormProps {
  id?: number;
  templateId?: number;
  columns?: ColumnsType<DataResponse>;
  handleCancel: () => void;
  forceFetchData?: () => void;
}

export interface ReportFormData {
  templateId: string;
  name: string;
  activeDate: moment.Moment;
  expireDate: moment.Moment;
  belongTo: BelongTo;
  autoApprove: boolean;
  templateCode: string;
}

export interface FormField {
  label: string;
  name: string;
  fileTypes: string[];
  require?: boolean;
  maxLength?: number;
}

export enum BelongTo {
  SET = 'SET',
  SEC = 'SEC',
}

export enum Status {
  APPROVE = 'Approve',
  WAITING = 'Waiting For Approve',
}
