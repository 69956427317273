import dayjs from 'dayjs';
import moment from 'moment';
import FileSaver from 'file-saver';
import styled from 'styled-components';
import { finalize } from 'rxjs/operators';
import { MAIN } from '@styles/constant/color';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { ModalCmp } from '@components/ModalCmp';
import { convertByteToMB } from '@helpers/utils';
import { ButtonSet } from '@components/ButtonSet';
import { Uploader } from 'pages/draft-report/Uploader';
import { ApiService } from '@core/services/api.service';
import { useSearchContext } from '@contexts/SearchProvider';
import { CustomService } from '@core/services/custom.service';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form, DatePicker, Space, Input, Popover, Button, Spin } from 'antd';
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import {
  Attachment,
  ViewDraftReportForm,
} from '@core/@models/ViewDraftReportModel';
import { NotificationType, useNotification } from '@helpers/use-notification';

interface ReportFormData {
  headline: string;
  periodDate: moment.Moment;
}

export interface ReportFormProps {
  reportId: number | null;
  templateId: number | null;
  templateName?: string;
  setModalTitle?: (title: string) => void;
  handleCancel: () => void;
}

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const dateFormat = 'DD/MM/YYYY';

export const ReportForm: React.FC<ReportFormProps> = ({ ...props }) => {
  const [form] = Form.useForm();
  const filesRef = useRef<File[]>([]);
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [data, setData] = useState<ViewDraftReportForm>();
  const { queryParam, handleQueryParam } = useSearchContext();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const apiService = useMemo(() => new ApiService(`/broker-report/draft`), []);
  const { open } = useNotification();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const url = props.reportId
      ? `/broker-report/draft?id=${props.reportId}`
      : `/broker-report/draft?templateId=${props.templateId}`;
    CustomService.getDataById<ViewDraftReportForm>(url)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: ViewDraftReportForm) => {
          props.setModalTitle && props.setModalTitle(response.title);
          setFormFieldsValue(response);
        },
      });
  };

  const setFormFieldsValue = (response: ViewDraftReportForm) => {
    setData(response);
    form.setFieldsValue({
      headline: response.headline,
      periodDate: response.periodDate
        ? moment(dayjs(response.periodDate).format('YYYY-MM-DD'))
        : undefined,
    });
    response.attachments.forEach((a) => {
      if (a.attachment)
        form.setFieldsValue({
          [`${a.fileName}Form`]: a.attachment,
        });
    });
  };

  const onFinish = (reportFormData: ReportFormData) => {
    setLoading(true);
    const action$ = apiService.createData<FormData, ViewDraftReportForm>(
      transformData(reportFormData)
    );
    action$.pipe(finalize(() => setLoading(false))).subscribe({
      next: (response: ViewDraftReportForm) => {
        handleQueryParam(queryParam);
        setErrorMessage(response.error);
        if (!response.error) props.handleCancel();
      },
    });
  };

  const transformData = (reportFormData: ReportFormData): FormData => {
    const formData = new FormData();
    const periodDate = reportFormData.periodDate;
    const blob = new Blob(
      [
        JSON.stringify({
          ...data,
          periodDate: dayjs(periodDate.toString()).format('YYYY-MM-DD'),
        }),
      ],
      { type: 'application/json' }
    );
    formData.append('=data=', blob, 'data.json');
    filesRef.current.forEach(
      (f, index) => data && formData.append(data.attachments[index].fileName, f)
    );
    return formData;
  };

  const handleUpload = (
    file: File,
    index: number,
    formData: ViewDraftReportForm
  ) => {
    filesRef.current[index] = file;
    handleRemove(index, formData);
    const fileName = formData.attachments[index].fileName;
    form.setFieldsValue({
      [`${fileName}Form`]: filesRef.current[index].name,
      [`${fileName}`]: filesRef.current[index].name,
    });
  };

  const handleRemove = (index: number, formData: ViewDraftReportForm) => {
    const { attachments } = formData;
    form.setFieldsValue({
      [`${attachments[index].fileName}Form`]: undefined,
    });
    if (attachments[index].attachment) attachments[index].delete = true;
    attachments[index].attachment = undefined;
    setData({ ...formData, attachments });
  };

  const handleDownload = (index: number, formData: ViewDraftReportForm) => {
    const { reportId, attachments } = formData;
    setFileLoading(true);
    CustomService.downloadFile(
      `/broker-report/download-file?reportId=${reportId}&fileName=${attachments[index].attachment}`
    )
      .pipe(finalize(() => setFileLoading(false)))
      .subscribe({
        next: (result: Blob) => {
          const blob = new Blob([result]);
          FileSaver.saveAs(blob, attachments[index].attachment);
        },
        error: () => {
          open({
            type: NotificationType.ERROR,
            description: t('common:downloadFailed'),
            disableDate: true,
          });
        },
      });
  };

  const getInitialFileList = (attachmentFile: Attachment) => {
    return attachmentFile.attachment
      ? [
          {
            uid: attachmentFile.attachment,
            name: attachmentFile.attachment,
            status: 'done',
          },
        ]
      : [];
  };

  const getUploaderProps = (formData: ViewDraftReportForm) => {
    return {
      disableSetFileList: true,
      showRemoveIcon: true,
      showDownloadIcon: true,
      handleUpload: (file: File, index: number) =>
        handleUpload(file, index, formData),
      handleRemove: (index: number) => handleRemove(index, formData),
      handleDownload: (index: number) => handleDownload(index, formData),
      buttonProps: { style: { width: 180 } },
    };
  };

  const getWarningTooltip = (error?: string) => {
    return error
      ? {
          title: (
            <>
              <Error>[Error]</Error>
              {ReactHtmlParser(error)}
            </>
          ),
          icon: <WarningOutlined style={{ color: MAIN.WARNING }} />,
        }
      : undefined;
  };

  const showDescription = (index: number, formData: ViewDraftReportForm) =>
    index === formData.attachments.length - 1 && formData.description;

  return (
    <Spin spinning={fileLoading}>
      <Form
        data-testid="view-report-form"
        className="view-report-form"
        {...layout}
        form={form}
        preserve={false}
        onFinish={onFinish}
        onClick={(e) => e.stopPropagation()}
      >
        <Form.Item
          name="headline"
          label={t('reportHeadline')}
          rules={[{ required: true }]}
        >
          <Input
            data-testid="report-headline-input"
            disabled
            style={{ width: 270 }}
          />
        </Form.Item>
        <Form.Item
          name="periodDate"
          label={t('dateAsOf')}
          rules={[
            {
              required: true,
              message: t('common:errorMessage.required', {
                labelName: ` ${t('common:dateAsOf')}`,
              }),
            },
          ]}
        >
          <DatePicker
            data-testid="date-as-of-picker"
            allowClear={false}
            format={dateFormat}
            style={{ width: 270 }}
            placeholder={t('button.select')}
          />
        </Form.Item>
        {data &&
          data.attachments.map((f, i) => (
            <>
              <Form.Item
                name={`${f.fileName}Form`}
                style={{ marginBottom: showDescription(i, data) ? 0 : 24 }}
                label={
                  t(`${f.label}`) +
                  (!f.isRequire ? ` (${t('ifApplicable')})` : '')
                }
                rules={[
                  {
                    required: f.isRequire,
                    message: t('errorMessage.pleaseSelectFile'),
                  },
                ]}
                tooltip={i === 0 ? getWarningTooltip(data.error) : undefined}
              >
                <Space align="baseline" style={{ width: '100%' }}>
                  <Form.Item name={f.fileName} style={{ marginBottom: 0 }}>
                    <Input
                      data-testid={`${f.fileName}-input`}
                      placeholder={t(`${f.label}`)}
                      style={{ width: 270 }}
                      disabled
                    />
                  </Form.Item>
                  <Uploader
                    index={i}
                    maxSize={convertByteToMB(f.limitFileSize)}
                    fileTypes={f.fileTypes || []}
                    initialFileList={getInitialFileList(f)}
                    {...getUploaderProps(data)}
                  />
                  <Popover
                    content={
                      <Space direction="vertical">
                        <div>
                          <Label>{t('supportFileTypes')}: </Label>
                          {(f.fileTypes || []).toString()}
                        </div>
                        <div>
                          <Label>{t('File limit size')}: </Label>
                          {convertByteToMB(f.limitFileSize)} MB
                        </div>
                      </Space>
                    }
                  >
                    <InfoCircleOutlined style={{ color: '#FCB034' }} />
                  </Popover>
                </Space>
                {showDescription(i, data) && (
                  <Form.Item label=" " colon={false}>
                    <DescriptionContent>{data.description}</DescriptionContent>
                  </Form.Item>
                )}
              </Form.Item>
            </>
          ))}
        <Form.Item label=" " colon={false}>
          <span>
            * {t('totalMaximumFileSize')}:{' '}
            {convertByteToMB(data?.totalLimitFileSize || 0)} MB
          </span>
        </Form.Item>
        <ButtonSet handleCancel={props.handleCancel} loading={loading} />
      </Form>
      {errorMessage && (
        <ModalCmp visible={!!errorMessage} title={`Error`} width={700}>
          <Error>[Error]</Error>
          {ReactHtmlParser(errorMessage)}
          <BtnWrapper>
            <Button
              data-testid="close-error-button"
              style={{ width: '80px' }}
              type="primary"
              ghost
              onClick={() => {
                setErrorMessage(undefined);
                props.handleCancel();
              }}
            >
              {t('button.close')}
            </Button>
          </BtnWrapper>
        </ModalCmp>
      )}
    </Spin>
  );
};

const BtnWrapper = styled(Space)`
  direction: 'vertical';
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;

const DescriptionContent = styled.span`
  font-weight: bold;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Error = styled.div`
  color: ${MAIN.WARNING};
  font-weight: bold;
`;
