export const MAIN = {
  WARNING: '#e86a6a',
  DEFAULT_BLACK: 'rgba(0, 0, 0, 0.85)',
  BLUE: '#3E94FF',
  GREEN: '#32cd32',
};

export const REPORT_STATUS = {
  C: MAIN.WARNING,
  A: MAIN.BLUE,
  R: MAIN.GREEN,
  D: MAIN.DEFAULT_BLACK,
  X: MAIN.DEFAULT_BLACK,
  N: MAIN.GREEN,
};
