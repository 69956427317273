import { DownOutlined, ProfileOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { DropDownProps } from 'antd/lib/dropdown';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface DropDownButtonProps {
  buttonProps?: ButtonProps;
  dropdownProps: DropDownProps;
}

export const DropDownButton: React.FC<DropDownButtonProps> = ({
  children,
  buttonProps,
  ...props
}) => {
  const { t } = useTranslation(['common']);
  return (
    <Wrapper>
      <Dropdown
        placement="bottomCenter"
        trigger={['click', 'hover']}
        {...props.dropdownProps}
      >
        <Button
          data-testid="dropdown-button"
          className="custom-btn"
          type="primary"
          icon={<ProfileOutlined />}
          {...buttonProps}
        >
          {children || (
            <Space align="center" style={{ marginLeft: 10 }}>
              {t('button.add')}
              <DownOutlined style={{ fontSize: 12 }} />
            </Space>
          )}
        </Button>
      </Dropdown>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px;
`;
