import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib/button';
import { Button, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonAdd } from '@components/ButtonAdd';
import { DataResponse } from '@core/@models/Types';
import { ManageNativeForm } from './ManageNativeForm';
import { useDataFetching } from '@helpers/use-data-fetching';
import { CustomService } from '@core/services/custom.service';
import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useModalManagement } from '@helpers/use-modal-management';
import {
  ManageNativeFormProps,
  ManageNativeResponse,
} from '@core/@models/ManageNativeModel';

const actionStyle: ButtonProps = { type: 'link', size: 'small' };

export const ManageNativeList = (): React.ReactElement => {
  const { t } = useTranslation(['common']);
  const { visible, hideModal, showModal } = useModalManagement();
  const [formProps, setFormProps] = useState<ManageNativeFormProps>({
    handleCancel: hideModal,
  });
  const { data, loading, fetchData, onSearch } = useDataFetching('natives');

  const getActionsView = (record: ManageNativeResponse): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Tooltip title={t('edit')}>
        <Button
          {...actionStyle}
          icon={<EditOutlined />}
          onClick={() => onClickRow(record)}
        />
      </Tooltip>
      <Tooltip title={t('button.delete')}>
        <Button
          {...actionStyle}
          style={{ marginLeft: 2 }}
          icon={<DeleteOutlined />}
          onClick={() => showConfirmModal(record.id)}
        />
      </Tooltip>
    </div>
  );

  const showConfirmModal = (id?: number) =>
    ConfirmModal({ onOk: () => onDelete(id) });

  const onDelete = (id: number | undefined) => {
    CustomService.deleteData(`/natives/${id}`).subscribe({
      next: () => fetchData(),
    });
  };

  const columns: ColumnsType<ManageNativeResponse> = [
    {
      title: t('code'),
      dataIndex: 'code',
      width: '15%',
      sorter: {
        compare: (a, b) => a.code.localeCompare(b.code),
      },
    },
    {
      title: t('name'),
      dataIndex: 'name',
      width: '75%',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: 'Action',
      className: 'last-column',
      width: '10%',
      render: getActionsView,
      align: 'center',
    },
  ];

  const handleOnClickRow = (record: ManageNativeResponse) => {
    return {
      onClick: () => onClickRow(record),
    };
  };

  const onClickRow = (record: ManageNativeResponse) => {
    setFormProps({ ...formProps, id: record.id });
    showModal();
  };

  const onClickAdd = () => {
    setFormProps({ ...formProps, id: undefined });
    showModal();
  };

  return (
    <>
      <ButtonAdd showModal={onClickAdd} />
      <Table
        size="small"
        data-testid="manage-native-table"
        className="selectable-table"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        onRow={handleOnClickRow}
        columns={columns}
        dataSource={onSearch<DataResponse>(data, ['code', 'name'])}
        loading={loading}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
      <ModalCmp
        visible={visible}
        title={formProps.id ? t('edit') : t('button.add')}
        width={700}
      >
        <ManageNativeForm {...formProps} />
      </ModalCmp>
    </>
  );
};
