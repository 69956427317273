import * as React from 'react';
import { RcFile } from 'antd/lib/upload';
import { ButtonProps } from 'antd/lib/button';
import { MAIN } from '@styles/constant/color';
import { Button, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { convertByteToMB } from '@helpers/utils';
import { useEffect, useImperativeHandle } from 'react';
import {
  UploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { NotificationType, useNotification } from '@helpers/use-notification';

interface UploaderProps {
  index: number;
  handleUpload: (file: File, index: number) => void;
  maxSize?: number;
  fileTypes: string[];
  initialFileList?: any[];
  buttonProps?: ButtonProps;
  handleRemove?: (index: number) => void;
  showRemoveIcon: boolean;
  disableSetFileList?: boolean;
  handleDownload?: (index: number) => void;
  showDownloadIcon?: boolean;
}
export const Uploader = React.forwardRef<unknown, UploaderProps>(
  ({ fileTypes, maxSize, ...props }, ref) => {
    const { t } = useTranslation(['common']);
    const { open } = useNotification();
    const [fileList, setFileList] = useState<RcFile[]>([]);
    const fileExtensions = fileTypes.join('|');
    const allowedExtensions = new RegExp(fileExtensions, 'i');

    useEffect(() => {
      setFileList(props.initialFileList || []);
    }, [props.initialFileList]);

    const validFileType = (file: File) => {
      const fileType = `.${file.name.split('.').pop()}`;
      return allowedExtensions.exec(fileType);
    };

    useImperativeHandle(
      ref,
      () => ({
        clearFileList: () => {
          setFileList([]);
        },
      }),
      [props.index]
    );

    const uploadProps = {
      accept: fileTypes.join(','),
      multiple: false,
      maxCount: 1,
      beforeUpload: (file: RcFile) => {
        let isMoreThanMaxSize = false;
        if (maxSize) {
          isMoreThanMaxSize = convertByteToMB(file.size) > maxSize;
          if (isMoreThanMaxSize) {
            open({
              type: NotificationType.ERROR,
              description: t('errorMessage.fileMaxSize', {
                maxSize: `${maxSize}MB`,
              }),
              disableDate: true,
            });
          }
        }
        if (!validFileType(file)) {
          open({
            type: NotificationType.ERROR,
            description: t('errorMessage.fileType'),
            disableDate: true,
          });
        }
        if (!isMoreThanMaxSize && validFileType(file)) {
          props.handleUpload(file, props.index);
          if (!props.disableSetFileList) setFileList([file]);
        }
        return false;
      },
      showUploadList: {
        showRemoveIcon: props.showRemoveIcon,
        removeIcon: (
          <DeleteOutlined
            style={{ color: MAIN.WARNING }}
            onClick={() => {
              setFileList([]);
              props.handleRemove && props.handleRemove(props.index);
            }}
          />
        ),
        showDownloadIcon: props.showDownloadIcon,
        downloadIcon: (
          <DownloadOutlined
            style={{ color: '#FCB034' }}
            onClick={() =>
              props.handleDownload && props.handleDownload(props.index)
            }
          />
        ),
      },
      fileList,
    };
    return (
      <Upload {...uploadProps} data-testid={`${props.index}-uploader`}>
        <Button
          data-testid={`${props.index}-upload-button`}
          className="upload"
          icon={<UploadOutlined />}
          {...props.buttonProps}
        >
          {t('selectFile')}
        </Button>
      </Upload>
    );
  }
);

Uploader.displayName = 'Uploader';
