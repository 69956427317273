import React from 'react';
import { SearchProvider } from '@contexts/SearchProvider';
import { ReportTemplateList } from './ReportTemplateList';
import { ReportTemplateSearch } from './ReportTemplateSearch';
import {
  ReportTemplateQueryParam,
  ReportTemplateSearchOptions,
} from '@core/@models/ReportTemplateModel';

export const ReportTemplate: React.FC = () => {
  const initialParam: ReportTemplateQueryParam = {
    name: null,
    belongTo: undefined,
  };

  return (
    <SearchProvider<ReportTemplateQueryParam, ReportTemplateSearchOptions>
      initialParam={initialParam}
    >
      <ReportTemplateSearch />
      <ReportTemplateList />
    </SearchProvider>
  );
};
