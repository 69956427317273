import { CalendarOutlined, FileTextOutlined } from '@ant-design/icons';
import { ModalCmp } from '@components/ModalCmp';
import {
  AttentionViewBody,
  AttentionViewResponse,
} from '@core/@models/AttentionModel';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { useModalManagement } from '@helpers/use-modal-management';
import { convertToDateShort } from '@helpers/utils';
import { Button, Card, Col, List, Row, Tag } from 'antd';
import { AttentionPreview } from 'pages/attention/AttentionPreview';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const AttentionView: React.FC = () => {
  const { t } = useTranslation(['common']);
  const [publicAttention, setPublicAttention] = useState<AttentionViewBody[]>(
    []
  );
  const [memberAttention, setMemberAttention] = useState<AttentionViewBody[]>(
    []
  );
  const lang = LanguageService.getLanguage();
  const [id, setId] = useState<number | null>(null);
  const handleClick = (reqId: number | null) => setId(reqId);
  const { visible, hideModal, showModal } = useModalManagement();
  const isExistsData = publicAttention.length > 1 || memberAttention.length > 1;

  useEffect(() => {
    let mounted = true;
    if (isExistsData) return;
    if (mounted) {
      getData();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const getData = () => {
    CustomService.getData<AttentionViewResponse>(`/attentions/view`).subscribe({
      next: (result: AttentionViewResponse) => {
        if (!result) return;
        setPublicAttention(result.forPublic);
        setMemberAttention(result.forMember);
      },
    });
  };

  const getHeader = (title: string) => (
    <Header>
      <FileIcon />
      <Title>{title}</Title>
    </Header>
  );

  const getDescription = (value: AttentionViewBody) => (
    <>
      <CalendarIcon />
      <Description>{convertToDateShort(value.publishBegin, lang)}</Description>
      <Tag
        color="red"
        visible={value.new}
        style={{ marginLeft: '10px', fontWeight: 700 }}
      >
        NEW !!
      </Tag>
    </>
  );

  const handleShowModal = (selectedId: number) => {
    handleClick(selectedId);
    showModal();
  };

  const columns = [
    {
      title: t('attentionForAllUsers'),
      data: publicAttention,
      padding: 10,
    },
    {
      title: t('attentionForMember'),
      data: memberAttention,
    },
  ];

  return (
    <>
      <Row>
        {columns.map((col) => (
          <Col key={col.title} span={12} style={{ paddingRight: col.padding }}>
            <Card style={{ borderRadius: 8 }} bodyStyle={{ padding: 20 }}>
              <List
                data-testid={`${col.title}-list`}
                size="small"
                itemLayout="horizontal"
                dataSource={col.data}
                header={getHeader(col.title)}
                renderItem={(item) => (
                  <List.Item onClick={() => handleShowModal(item.id)}>
                    <List.Item.Meta
                      title={item.headLine}
                      description={getDescription(item)}
                    />
                  </List.Item>
                )}
                pagination={{
                  pageSize: 5,
                  showTotal: (total) => t('totalItem', { total }),
                }}
              />
            </Card>
          </Col>
        ))}
      </Row>
      <ModalCmp width={1000} visible={visible} title={t('preview')}>
        <AttentionPreview id={id}>
          <Row className="button-set">
            <Button
              ghost
              type="primary"
              onClick={hideModal}
              style={{ width: '80px' }}
            >
              {t('button.close')}
            </Button>
          </Row>
        </AttentionPreview>
      </ModalCmp>
    </>
  );
};

const FileIcon = styled(FileTextOutlined)`
  margin-right: 5px;
  margin-top: 5px;
`;

const CalendarIcon = styled(CalendarOutlined)`
  margin-right: 5px;
`;

const Header = styled.div`
  display: inline-flex;
`;

const Title = styled.h3`
  font-weight: 600;
`;

const Description = styled.span`
  font-size: 12px;
`;
