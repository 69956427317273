import React, { useState } from 'react';
import FileSaver from 'file-saver';
import styled from 'styled-components';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Button, Card, Table, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CustomService } from '@core/services/custom.service';
import { NotificationType, useNotification } from '@helpers/use-notification';
import { finalize } from 'rxjs/operators';

export const DownloadXmlFileView: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { open } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  const getFileView = (name: string): JSX.Element => <>{`${name}.xml`}</>;

  const getDownloadFileView = (label: string): JSX.Element => (
    <Tooltip title={t('download')}>
      <DownloadButton
        type="link"
        size="small"
        icon={<DownloadOutlined />}
        onClick={() => downloadFile(label)}
      />
    </Tooltip>
  );

  const downloadFile = (name: string) => {
    setLoading(true);
    CustomService.downloadFile(
      `/broker-report-group/download-xml?file=${name.toUpperCase()}`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result: Blob) => {
          const blob = new Blob([result]);
          FileSaver.saveAs(blob, `${name}.xml`);
        },
        error: () => {
          open({
            type: NotificationType.ERROR,
            description: t('common:downloadFailed'),
            disableDate: true,
          });
        },
      });
  };

  const data = [
    { name: 'branch' },
    { name: 'province' },
    { name: 'amphur' },
    { name: 'native' },
  ];

  const columns: ColumnsType<{ name: string }> = [
    {
      title: t('files'),
      dataIndex: 'name',
      render: getFileView,
    },
    {
      title: t('download'),
      dataIndex: 'name',
      align: 'right',
      render: getDownloadFileView,
      className: 'last-column',
    },
  ];

  return (
    <Card
      style={{ borderRadius: 8 }}
      headStyle={{ padding: '0px 10px', borderBottom: 'none' }}
      bodyStyle={{ padding: '0px 10px', borderBottom: 'none' }}
      title={<Title>{t('additionalFiles')}</Title>}
    >
      <Table
        size="small"
        data-testid="additional-file-table"
        className="unselectable-table additional-file-table"
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey={(record) => record.name}
        loading={loading}
      />
    </Card>
  );
};

const DownloadButton = styled(Button)`
  margin-right: 18px;
`;

const Title = styled.span`
  font-weight: 800;
  font-size: 14px;
  color: #231f20;
`;
