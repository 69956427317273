import { Breadcrumb } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
export const BreadcrumbCmp: React.FC<{
  menu: { id: number; title: string };
  subMenu: { id: number; title: string };
}> = ({ menu, subMenu }) => {
  const location = useLocation();
  const extraBreadcrumbItems = (
    <>
      {menu && <Breadcrumb.Item key={menu.id}> {menu.title}</Breadcrumb.Item>}
      {subMenu && (
        <Breadcrumb.Item key={subMenu.id}>
          <Link to={`${location.pathname}${location.search}`}>
            {subMenu.title}
          </Link>
        </Breadcrumb.Item>
      )}
    </>
  );
  const breadcrumbItems = [
    <Breadcrumb.Item key="1">
      <Link to={'/'}>Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
};
