import React from 'react';
import { DraftReportList } from './DraftReportList';
import { DraftReportSearch } from './DraftReportSearch';
import {
  DraftReportSearchOptions,
  DraftReportQueryParam,
  DraftReportResponseBody,
} from '@core/@models/DraftReportModel';
import { SearchProvider } from '@contexts/SearchProvider';
import { SortDirection } from '@core/@models/PaginationModal';

export const DraftReport: React.FC = () => {
  const initialParam: DraftReportQueryParam = {
    templateId: undefined,
    periodId: null,
    statusId: null,
    begin: null,
    end: null,
    sort: `updateDate,${SortDirection.DESC}`,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<DraftReportQueryParam, DraftReportSearchOptions>
      initialParam={initialParam}
    >
      <DraftReportSearch />
      <DraftReportList<DraftReportResponseBody> />
    </SearchProvider>
  );
};
