import Icon, { UserOutlined } from '@ant-design/icons';
import { REPORT_STATUS_KEY, Updater } from '@core/@models/DraftReportModel';
import { DataResponseBody } from '@core/@models/Types';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { Popover, Space, Tooltip } from 'antd';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';
import { Status } from 'pages/draft-report/DraftReportForm';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { convertToDateTimeShort } from './utils';
import { finalize } from 'rxjs/operators';
import { NotificationType, useNotification } from './use-notification';

export interface UseBrokerReportProps {
  downloadFile: (queryString: string) => void;
  fileLoading: boolean;
  renderDownloadFile: (
    component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    url: string,
    title?: string
  ) => JSX.Element;
  renderStatus: (value: string, record: DataResponseBody) => JSX.Element;
  renderShortDateTime: (date: string | null) => JSX.Element;
  renderLastUpdateDate: (
    date: string | null,
    user: Updater | null
  ) => JSX.Element;
}

export const useBrokerReport = (): UseBrokerReportProps => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const [fileLoading, setFileLoading] = React.useState<boolean>(false);
  const { open } = useNotification();

  const downloadFile = (url: string) => {
    setFileLoading(true);
    CustomService.download(url)
      .pipe(finalize(() => setFileLoading(false)))
      .subscribe({
        next: (result: AxiosResponse<Blob>) => {
          const { data, headers } = result;
          const fileName = headers['content-disposition']
            .split(';')[1]
            .split('=')[1]
            .replace('"', '')
            .replace('"', '');

          const blob = new Blob([data]);
          FileSaver.saveAs(blob, fileName);
        },
        error: () => {
          open({
            type: NotificationType.ERROR,
            description: t('common:downloadFailed'),
            disableDate: true,
          });
        },
      });
  };

  const renderDownloadFile = (
    component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    url: string,
    title?: string
  ) => {
    return (
      <span
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
          event.stopPropagation()
        }
      >
        <Tooltip title={t('download')}>
          <Icon
            component={component}
            className="customIcon"
            title={title ? title : 'download any file'}
            onClick={() => downloadFile(url)}
          />
        </Tooltip>
      </span>
    );
  };

  const renderStatus = (value: string, record: DataResponseBody): JSX.Element =>
    'status' in record && record.status ? (
      <Status status={record.status as REPORT_STATUS_KEY}>{value}</Status>
    ) : (
      <>{value}</>
    );

  const renderShortDateTime = (date: string | null): React.ReactElement => (
    <>{date && `${convertToDateTimeShort(date, lang)} `}</>
  );

  const renderLastUpdateDate = (date: string | null, user: Updater | null) => (
    <>
      {date && `${convertToDateTimeShort(date, lang)}`}
      {user && (
        <Popover
          content={
            <Space direction="vertical">
              <div>
                <Label>{`${t('name')}: `}</Label>
                {user.loginName}
              </div>
              <div>
                <Label>{`${t('telephoneNumber')}: `}</Label>
                {user.telephone}
              </div>
            </Space>
          }
        >
          <UserOutlinedIcon />
        </Popover>
      )}
    </>
  );

  return {
    downloadFile,
    fileLoading,
    renderDownloadFile,
    renderStatus,
    renderShortDateTime,
    renderLastUpdateDate,
  };
};

const Label = styled.label`
  font-weight: bold;
`;

const UserOutlinedIcon = styled(UserOutlined)`
  color: #fcb034;
`;
