import { REPORT_STATUS } from '@styles/constant/color';
import { Pagable, PagingQuery } from './PaginationModal';

export interface DraftReportQueryParam extends PagingQuery {
  companyId?: number;
  templateId: number | undefined;
  periodId: string | null;
  statusId: string | null;
  begin: string | null;
  end: string | null;
}

export interface DraftReportResponse extends Pagable {
  content: DraftReportResponseBody[];
}

export interface DraftReportResponseBody {
  id: number;
  brokerCompanyNo: number;
  brokerCompanyCode: string;
  periodName: string;
  periodDate: string;
  updateDate: string;
  updater: Updater;
  status: REPORT_STATUS_KEY;
  statusName: string;
  canSend: boolean;
}

export interface Updater {
  id: number;
  loginName: string;
  title: string;
  nameTh: string;
  nameEn: string;
  surnameTh: string;
  surnameEn: string;
  telephone: string;
  mobile: string;
}

export interface DraftReportConfigFormResponse {
  brokerCompanies: { id: number; name: string }[];
  periods: { id: number; name: string }[];
  templateNames: { id: number; name: string }[];
  statuses: { id: string; name: string }[];
}

export type ViewDraftReportConfigFormResponse = Omit<
  DraftReportConfigFormResponse,
  'periods'
> & { dateTypes: string[] };

export interface DraftReportFormList {
  reportId: number;
  templateId: number;
  templateName: string;
  templateCode: string;
  updater: Updater | null;
  status: REPORT_STATUS_KEY;
  statusName: string | null;
  updateDate: string | null;
  fileName: string;
  error: string | null;
  isReview: boolean;
  isDigest: boolean;
  isUpload: true;
  isDelete: boolean;
  order: number;
  delete: boolean;
  fileTypes: string[];
  limitFileSize: number;
}
export type REPORT_STATUS_KEY = keyof typeof REPORT_STATUS;
export interface DraftReportFormData {
  periodDate: string;
  period: DraftReportPeriod;
  periodName: string;
  brokerCompanyNo: string;
  brokerCompanyCode: string;
  id: number;
  status: REPORT_STATUS_KEY;
  statusName: string;
  reports: DraftReportFormList[];
}

export enum DraftReportStatus {
  APPROVED = 'A',
  CANCEL = 'C',
  DRAFT = 'D',
  DRART_COMPLETE = 'R',
  WAIT_FOR_APPROVE = 'N',
  DELETE = 'X',
}

export enum DraftReportPeriod {
  DAILY = 'D',
  MONTHLY = 'M',
}

export enum ReportPage {
  DRAFT = 'Draft',
  SENT = 'Sent',
}

export interface DraftReportSearchOptions {
  brokerCompanies: { label: string; value: string }[];
  templateNames: { label: string; value: string }[];
  periods: { label: string; value: string }[];
  statuses: { label: string; value: string }[];
}
