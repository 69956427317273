import { SearchProvider } from '@contexts/SearchProvider';
import { SortDirection } from '@core/@models/PaginationModal';
import {
  SentDraftReportReponseBody,
  ViewDraftReportSearchOptions,
  ViewSentReportQueryParam,
} from '@core/@models/ViewDraftReportModel';
import { ReportList } from 'pages/view-draft-report/ReportList';
import { ReportSearch } from 'pages/view-draft-report/ReportSearch';
import * as React from 'react';

export const ViewSentReport: React.FC = () => {
  const initialParam: ViewSentReportQueryParam = {
    companyId: undefined,
    templateId: undefined,
    statusId: null,
    period: undefined,
    dateType: undefined,
    begin: null,
    end: null,
    sort: `receiveDate,${SortDirection.DESC}`,
    size: 15,
    page: 1,
  };

  return (
    <SearchProvider<ViewSentReportQueryParam, ViewDraftReportSearchOptions>
      initialParam={initialParam}
    >
      <ReportSearch />
      <ReportList<SentDraftReportReponseBody> />
    </SearchProvider>
  );
};
