import { PageNotFound } from '@components/PageNotFound';
import { PermissionDeny } from '@components/PermissionDeny';
import { DefaultLayout } from '@layouts/DefaultLayout';
import { HomeLayout } from '@layouts/HomeLayout';
import { Layouts } from '@layouts/Layouts';
import { PermissionLayout } from '@layouts/PermissionLayout';
import { createBrowserHistory } from 'history';
import { Home } from 'pages/Home';
import { DraftReport } from 'pages/draft-report';
import { ManageNative } from 'pages/manage-native';
import { ManageZone } from 'pages/manage-zone';
import { ReportTemplate } from 'pages/report-template';
import { SentReport } from 'pages/sent-report';
import { ViewDraftReport } from 'pages/view-draft-report';
import { ViewSentReport } from 'pages/view-sent-report';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import CheckAccessTimeRoute from './CheckAccssTimeRoute';

export const history = createBrowserHistory();

const routes = [
  {
    path: '/broker-report-group/draft',
    exact: true,
    component: DraftReport,
  },
  {
    path: '/broker-report-group/sent',
    exact: true,
    component: SentReport,
  },
  {
    path: '/broker-report/draft',
    exact: true,
    component: ViewDraftReport,
  },
  {
    path: '/broker-report/sent',
    exact: true,
    component: ViewSentReport,
  },
  {
    path: '/broker-report-template/manage',
    exact: true,
    component: ReportTemplate,
  },
  {
    path: '/broker-report-template/approve',
    exact: true,
    component: ReportTemplate,
  },
  {
    path: '/native',
    exact: true,
    component: ManageNative,
  },
  {
    path: '/zone',
    exact: true,
    component: ManageZone,
  },
];

export const Routers: React.FC = () => (
  <Router history={history}>
    <Switch>
      <Route path={['/auth', '/']} exact>
        <Layouts layout={HomeLayout} isPublic={true}>
          <Route component={Home} />
        </Layouts>
      </Route>

      <Layouts layout={DefaultLayout} isPublic={true}>
        {routes.map((r) => {
          return (
            <CheckAccessTimeRoute
              key={r.path}
              path={r.path}
              component={r.component}
              exact={r.exact}
            />
          );
        })}
        <Route path={'/error/access'} exact component={PermissionDeny} />
      </Layouts>

      <Route path="*">
        <Layouts layout={PermissionLayout} isPublic={true}>
          <Route component={PageNotFound} />
        </Layouts>
      </Route>
    </Switch>
  </Router>
);
