import { FooterPage } from '@components/FooterPage';
import { Layout } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Headers } from './Headers';
const { Content } = Layout;

export const PermissionLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Headers />
      <Layout className="site-layout">
        <Layout style={{ padding: '0 24px 0 24px' }}>
          <Content
            className="site-layout-background"
            style={{ margin: '10px 16px 0', minHeight: 200 }}
          >
            {children}
          </Content>
          <FooterPage />
        </Layout>
      </Layout>
    </Layout>
  );
};
