import { DeleteOutlined, EditOutlined, SendOutlined } from '@ant-design/icons';
import { ModalCmp } from '@components/ModalCmp';
import { useSearchContext } from '@contexts/SearchProvider';
import {
  DraftReportResponseBody,
  ReportPage,
} from '@core/@models/DraftReportModel';
import { DataResponseBody } from '@core/@models/Types';
import { LanguageService } from '@core/services/language.service';
import { useBrokerReport } from '@helpers/use-broker-report';
import { useDataFetchingPagination } from '@helpers/use-data-fetching-pagination';
import { useModalManagement } from '@helpers/use-modal-management';
import { Button, Menu, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib/button';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import {
  ConfirmSendReportView,
  ConfirmSendReportViewProps,
} from './ConfirmSendReportView';
import { DownloadXmlFileView } from './DownloadXmlFileView';
import { DraftReportForm, DraftReportFormProps } from './DraftReportForm';
import { DropDownButton } from './DropdownButton';

const { Item } = Menu;
const actionStyle: ButtonProps = { type: 'link', size: 'small' };

export const DraftReportList = <
  T extends DataResponseBody
>(): React.ReactElement => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const { visible, hideModal, showModal } = useModalManagement();
  const [
    draftReportProps,
    setDraftReportProps,
  ] = useState<DraftReportFormProps>({
    id: null,
    periodName: 'daily',
    page: ReportPage.DRAFT,
    handleCancel: hideModal,
  });
  const {
    visible: confirmSendReportVisible,
    hideModal: hideConfirmSendReportModal,
    showModal: ShowConfirmSendReportModal,
  } = useModalManagement();
  const { reportStatus, menuId, roleId } = useSearchContext();
  const isSentReport = reportStatus.includes('sent');
  const page = isSentReport ? ReportPage.SENT : ReportPage.DRAFT;
  const {
    data,
    loading,
    pagination,
    handleTableChange,
    handleConfirmDeleteModal,
  } = useDataFetchingPagination<T>(`broker-report-group/${reportStatus}/list`, {
    'x-menu-id': menuId,
    'x-role-id': roleId,
  });

  const [
    ConfirmSendReportProps,
    setConfirmSendReportProps,
  ] = useState<ConfirmSendReportViewProps>({
    handleCancel: hideConfirmSendReportModal,
  });

  const { renderStatus, renderLastUpdateDate } = useBrokerReport();

  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);

  const reportId = urlSearchParams.get('reportId') || '';

  useEffect(() => {
    if (!reportId) return;
    goToForm(+reportId);
  }, [reportId]);

  const getReportPeriodView = (reportPeriod: string): React.ReactElement => (
    <>{t(`${reportPeriod}`)}</>
  );

  const getDatePeriodView = (datePeriod: string): React.ReactElement => (
    <>
      {lang === 'th'
        ? dayjs(datePeriod).format('DD / MM / BBBB')
        : dayjs(datePeriod).format('DD / MM / YYYY')}
    </>
  );

  const isDisableSend = (record: DataResponseBody) => {
    if ('canSend' in record) {
      return !record.canSend;
    }
    return true;
  };

  const getActionsView = (record: DataResponseBody): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Tooltip title={t('edit')}>
        <Button
          {...actionStyle}
          icon={<EditOutlined />}
          onClick={() => onClickSelectRow(record as DraftReportResponseBody)}
        />
      </Tooltip>
      <Tooltip title={t('button.send')}>
        <span
          style={{ pointerEvents: isDisableSend(record) ? 'none' : 'auto' }}
        >
          <SendButton
            {...actionStyle}
            className="send"
            icon={<SendOutlined />}
            disabled={isDisableSend(record)}
            onClick={() => handleShowConfirmSendReportModal(record)}
          />
        </span>
      </Tooltip>
      <Tooltip title={t('button.delete')}>
        <DeleteButton
          {...actionStyle}
          icon={<DeleteOutlined />}
          onClick={() => {
            const { id } = record as DraftReportResponseBody;
            handleConfirmDeleteModal(`/broker-report-group/draft/${id}`);
          }}
        />
      </Tooltip>
    </div>
  );

  const handleShowConfirmSendReportModal = (record: DataResponseBody) => {
    const {
      id,
      periodName,
      periodDate,
      statusName,
    } = record as DraftReportResponseBody;
    setConfirmSendReportProps({
      ...ConfirmSendReportProps,
      id,
      periodNameView: getReportPeriodView(periodName),
      periodDateView: getDatePeriodView(periodDate),
      statusView: renderStatus(statusName, record),
    });
    ShowConfirmSendReportModal();
  };

  const getColumns = (): ColumnsType<T> => {
    const columns: ColumnsType<T> = [
      {
        title: t('brokerNo'),
        dataIndex: 'brokerCompanyNo',
        key: 'company.companyId',
        width: '15%',
        sorter: true,
      },
      {
        title: t('brokerName'),
        dataIndex: 'brokerCompanyCode',
        key: 'company.code',
        width: '15%',
        sorter: true,
      },
      {
        title: t('reportPeriod'),
        dataIndex: 'periodName',
        key: 'period',
        width: '15%',
        render: getReportPeriodView,
        sorter: true,
      },
      {
        title: t('period'),
        dataIndex: 'periodDate',
        key: 'periodDate',
        width: '15%',
        render: getDatePeriodView,
        sorter: true,
      },
      {
        title: t('lastUpdate'),
        key: 'updateDate',
        width: '15%',
        render: (_, record: DataResponseBody) => {
          const { updateDate, updater } = record as DraftReportResponseBody;
          return renderLastUpdateDate(updateDate, updater);
        },
        sorter: true,
        defaultSortOrder: 'descend',
      },
      {
        title: t('status'),
        dataIndex: 'statusName',
        key: 'status',
        width: '15%',
        render: renderStatus,
        sorter: true,
        className: isSentReport ? 'last-column' : undefined,
      },
      {
        title: 'Action',
        width: '10%',
        align: 'center',
        className: 'last-column',
        render: getActionsView,
      },
    ];
    return isSentReport ? columns.slice(0, 6) : columns;
  };

  const handleSelectRow = (record: DataResponseBody) => {
    return {
      onClick: () => onClickSelectRow(record as DraftReportResponseBody),
    };
  };

  const goToForm = (id: number) => {
    const result = Object.assign({}, draftReportProps, {
      id,
      periodName: '',
      page,
    });
    setDraftReportProps(result);
    showModal();
  };

  const onClickSelectRow = (record: DraftReportResponseBody) => {
    const { id } = record;
    goToForm(id);
  };

  const handleMenuClick = ({ key: periodName }: any) => {
    setDraftReportProps({
      id: null,
      periodName,
      page,
      handleCancel: hideModal,
    });
    showModal();
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <MenuItem key="Daily">{t('Daily')}</MenuItem>
      <MenuItem key="Monthly">{t('Monthly')}</MenuItem>
    </Menu>
  );

  return (
    <>
      {!isSentReport && <DropDownButton dropdownProps={{ overlay: menu }} />}
      <ModalCmp visible={visible} title={`${page} Report`} width={1200}>
        <DraftReportForm {...draftReportProps} />
      </ModalCmp>
      <Table
        size="small"
        className="selectable-table"
        data-testid="draft-report-table"
        pagination={pagination}
        onRow={handleSelectRow}
        columns={getColumns()}
        dataSource={data}
        loading={loading}
        onChange={handleTableChange}
        sortDirections={['ascend', 'descend', 'ascend']}
        style={!isSentReport ? undefined : { marginTop: 20 }}
        rowKey={(record: DataResponseBody) =>
          (record as DraftReportResponseBody).id
        }
      />
      <div style={{ marginTop: data.length === 0 ? 20 : undefined }}>
        <DownloadXmlFileView />
      </div>
      <ModalCmp
        width={340}
        visible={confirmSendReportVisible}
        title={'Confirm - Send Report'}
      >
        <ConfirmSendReportView {...ConfirmSendReportProps} />
      </ModalCmp>
    </>
  );
};

const SendButton = styled(Button)`
  margin-left: 5px;
  transform: rotate(-40deg);
`;

const DeleteButton = styled(Button)`
  margin-left: 2px;
`;

const MenuItem = styled(Item)`
  color: #231f20 !important;
  font-size: 13px;
  transition: background-color 0.3s;
  :hover {
    color: #fcb034 !important;
  }
`;
