import moment from 'moment';
import styled from 'styled-components';
import React, { useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { MAIN } from '@styles/constant/color';
import { ButtonProps } from 'antd/lib/button';
import { Button, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonAdd } from '@components/ButtonAdd';
import { DataResponse } from '@core/@models/Types';
import { convertToDateShort } from '@helpers/utils';
import { ReportTemplateForm } from './ReportTemplateForm';
import { VersionTemplateList } from './VersionTemplateList';
import { useSearchContext } from '@contexts/SearchProvider';
import { useDataFetching } from '@helpers/use-data-fetching';
import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { CustomService } from '@core/services/custom.service';
import { LanguageService } from '@core/services/language.service';
import { EditOutlined, FileDoneOutlined } from '@ant-design/icons';
import { useModalManagement } from '@helpers/use-modal-management';
import {
  ReportTemplateFormProps,
  ReportTemplateResponse,
  Status,
} from '@core/@models/ReportTemplateModel';

const actionStyle: ButtonProps = { type: 'link', size: 'small' };

export const ReportTemplateList = (): React.ReactElement => {
  const { t } = useTranslation(['common']);
  const lang = LanguageService.getLanguage();
  const { reportStatus } = useSearchContext();
  const isApproveTemplate = reportStatus.includes('approve');
  const { visible, hideModal, showModal } = useModalManagement();
  const [formProps, setFormProps] = useState<ReportTemplateFormProps>({
    handleCancel: hideModal,
  });
  const { data, loading, fetchData } = useDataFetching(
    `/broker-templates/${isApproveTemplate ? 'draft' : 'all'}`
  );

  const renderStatus = (value: boolean): JSX.Element => (
    <span style={{ color: value ? `${MAIN.GREEN}` : `${MAIN.WARNING}` }}>
      {value ? t('approved') : t('waitingForApprove')}
    </span>
  );

  const getDateView = (date: string): React.ReactElement => (
    <>{convertToDateShort(date, lang)}</>
  );

  const getActionsView = (record: DataResponse): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Tooltip title={t('edit')}>
        <Button
          {...actionStyle}
          icon={<EditOutlined />}
          onClick={() => onClickSelectRow(record)}
        />
      </Tooltip>
      {isApproveTemplate && (
        <Tooltip title={t('button.approve')}>
          <ApproveButton
            {...actionStyle}
            icon={
              <FileDoneOutlined onClick={() => showConfirmModal(record.id)} />
            }
          />
        </Tooltip>
      )}
    </div>
  );

  const showConfirmModal = (id: number) => {
    ConfirmModal({
      onOk: () => handleApprove(id),
      content: t('confirmContent', {
        contentName: t('common:button:approve').toLowerCase(),
      }),
    });
  };

  const handleApprove = (id: number) => {
    CustomService.createData(`/broker-templates/${id}/approve`, {}).subscribe({
      next: () => fetchData(),
    });
  };

  const defaultColumns: ColumnsType<DataResponse> = [
    {
      title: t('name'),
      dataIndex: 'name',
      width: '30%',
      sorter: {
        compare: (a, b) => (a.name || '').localeCompare(b.name || ''),
      },
    },
    {
      title: t('belongTo'),
      dataIndex: 'belongTo',
      width: '10%',
      sorter: {
        compare: (a, b) => (a.belongTo || '').localeCompare(b.belongTo || ''),
      },
    },
    {
      title: t('activeDate'),
      dataIndex: 'activeDate',
      width: '13%',
      render: getDateView,
      sorter: {
        compare: (a, b) =>
          moment(a.activeDate).unix() - moment(b.activeDate).unix(),
      },
    },
    {
      title: t('expiryDate'),
      dataIndex: 'expireDate',
      width: '13%',
      render: getDateView,
      sorter: {
        compare: (a, b) =>
          moment(a.expireDate).unix() - moment(b.expireDate).unix(),
      },
    },
    {
      title: t('approveStatus'),
      key: 'approved',
      dataIndex: 'approved',
      width: '15%',
      render: renderStatus,
      sorter: {
        compare: (a, b) =>
          (a.approved ? Status.APPROVE : Status.WAITING).localeCompare(
            b.approved ? Status.APPROVE : Status.WAITING
          ),
      },
    },
  ];

  const getColumns = (): ColumnsType<DataResponse> => {
    const columns: ColumnsType<DataResponse> = [
      {
        title: t('templateId'),
        dataIndex: 'templateId',
        width: '12%',
        sorter: {
          compare: (a, b) => a.templateId - b.templateId,
        },
      },
      ...defaultColumns,
      {
        title: 'Action',
        className: 'last-column',
        width: '7%',
        render: getActionsView,
        align: 'center',
      },
    ];
    return isApproveTemplate
      ? columns.filter((c) => c.key !== 'approved')
      : columns;
  };

  const handleSelectRow = (record: DataResponse) => {
    return {
      onClick: () => onClickSelectRow(record),
    };
  };

  const onClickSelectRow = (record: ReportTemplateResponse) => {
    const { templateId } = record;
    setFormProps({
      ...formProps,
      templateId,
    });
    showModal();
  };

  const onClickAdd = () => {
    setFormProps({
      ...formProps,
      templateId: undefined,
    });
    showModal();
  };

  const showVersionList = formProps.templateId !== undefined;

  return (
    <>
      {!isApproveTemplate && <ButtonAdd showModal={onClickAdd}></ButtonAdd>}
      <Table
        size="small"
        data-testid="report-template-table"
        className="selectable-table"
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        onRow={handleSelectRow}
        columns={getColumns()}
        dataSource={data}
        loading={loading}
        sortDirections={['ascend', 'descend', 'ascend']}
        style={!isApproveTemplate ? undefined : { marginTop: 20 }}
      />
      <ModalCmp
        visible={visible}
        title={
          showVersionList
            ? 'Broker Report Template Versions'
            : t('addBrokerReportTemplate')
        }
        width={showVersionList ? 1200 : 700}
      >
        {showVersionList ? (
          <VersionTemplateList {...formProps} columns={defaultColumns} />
        ) : (
          <ReportTemplateForm {...formProps} />
        )}
      </ModalCmp>
    </>
  );
};

const ApproveButton = styled(Button)`
  margin-left: 5px;
`;
