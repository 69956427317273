import styled from 'styled-components';
import { Modal } from 'antd';
import React from 'react';
import { ModalFunc } from 'antd/lib/modal/confirm';
import i18n from '../i18n';
import { ModalFuncProps } from 'antd/lib/modal';
import exclamation from 'assets/icon/exclamation.png';
interface ModalProps {
  visible: boolean;
  title: string;
  width?: number;
  children?: React.ReactNode;
}
export const ModalCmp = React.forwardRef<HTMLDivElement, ModalProps>(
  ({ visible, title, children, width }, ref) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Modal
          visible={visible}
          title={title}
          destroyOnClose={true}
          footer={null}
          width={width}
          maskClosable={false}
          centered
          closable={false}
        >
          <div ref={ref}>{children}</div>
        </Modal>
      </div>
    );
  }
);

export const ConfirmModal: ModalFunc = ({ onOk, content }) => {
  const config: ModalFuncProps = {
    width: 320,
    icon: (
      <>
        <Img src={exclamation} />
        <Title>{i18n.t('common:confirmTitle')}</Title>
      </>
    ),
    content: (
      <Content>
        {content
          ? content
          : i18n.t('common:confirmContent', {
              contentName: i18n.t('common:button:delete').toLowerCase(),
            })}
      </Content>
    ),
    cancelText: i18n.t('common:button.cancel'),
    okText: i18n.t('common:confirm'),
    autoFocusButton: null,
    cancelButtonProps: {
      ghost: true,
      type: 'primary',
      style: { width: '140px' },
    },
    okButtonProps: { style: { float: 'right', width: '140px' } },
  };
  const result = { ...config, onOk };
  return Modal.confirm(result);
};

ModalCmp.displayName = 'ModalCmp';

export const Img = styled.img`
  margin-left: 10px;
`;

export const Title = styled.span`
  font-weight: 700;
  margin-left: 20px;
  font-size: 16px;
`;

export const Content = styled.span`
  margin-left: 54px;
  font-size: 15px;
`;
