import instance from '@core/axios';
import { AxiosResponse } from 'axios';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export class CustomService {
  static getParam(params: any): { params: any } {
    if (params) {
      return {
        params,
      };
    }
    return params;
  }

  static download(url: string): Observable<AxiosResponse<Blob>> {
    return from(
      instance.get<Blob>(url, { responseType: 'blob' })
    );
  }

  static downloadFile(url: string): Observable<Blob> {
    return from(
      instance.get<Blob>(url, { responseType: 'blob' })
    ).pipe(map((d) => d.data));
  }

  static getData<R>(url: string, params?: any, headers?: any): Observable<R> {
    return from(
      instance.get<R>(
        url,
        Object.assign({}, CustomService.getParam(params), { headers: headers })
      )
    ).pipe(map((d) => d.data));
  }

  static getDataById<R>(
    url: string,
    headers?: { [key: string]: any }
  ): Observable<R> {
    return from(
      instance.get<R>(url, { headers })
    ).pipe(map((d) => d.data));
  }

  static postWithParam<T, R>(url: string, params: T): Observable<R> {
    return from(
      instance.post<R>(url, undefined, { params })
    ).pipe(map((d) => d.data));
  }

  static createData<T, R>(
    url: string,
    data: T | null,
    headers?: any
  ): Observable<R> {
    return from(
      instance.post<R>(url, data, { headers })
    ).pipe(map((d) => d.data));
  }

  static deleteData<T>(url: string, data?: T): Observable<null> {
    return from(
      instance.delete<null>(url, { data: data })
    ).pipe(map((d) => d.data));
  }
}
