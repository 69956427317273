import { useEffect } from 'react';
import styled from 'styled-components';
import React, { useState } from 'react';
import { finalize } from 'rxjs/operators';
import { ColumnsType } from 'antd/es/table';
import { ButtonProps } from 'antd/lib/button';
import { useTranslation } from 'react-i18next';
import { ButtonAdd } from '@components/ButtonAdd';
import { ReportTemplateForm } from './ReportTemplateForm';
import { useSearchContext } from '@contexts/SearchProvider';
import { CustomService } from '@core/services/custom.service';
import { ConfirmModal, ModalCmp } from '@components/ModalCmp';
import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useModalManagement } from '@helpers/use-modal-management';
import {
  ReportTemplateFormProps,
  ReportTemplateResponse,
  ReportTemplateVersionResponse,
} from '@core/@models/ReportTemplateModel';

const actionStyle: ButtonProps = { type: 'link', size: 'small' };

export const VersionTemplateList: React.FC<ReportTemplateFormProps> = ({
  templateId,
  columns,
  handleCancel,
}) => {
  const { t } = useTranslation(['common']);
  const [loading, setLoading] = useState<boolean>(false);
  const { visible, hideModal, showModal } = useModalManagement();
  const [data, setData] = useState<ReportTemplateVersionResponse>();
  const { handleQueryParam, queryParam, reportStatus } = useSearchContext();
  const isApproveTemplate = reportStatus.includes('approve');

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    CustomService.getDataById<ReportTemplateVersionResponse>(
      `/broker-templates/all/${templateId}`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (response: ReportTemplateVersionResponse) => setData(response),
      });
  };

  const [formProps, setFormProps] = useState<ReportTemplateFormProps>({
    templateId,
    handleCancel: hideModal,
    forceFetchData: getData,
  });

  const showConfirmModal = (id: number | undefined) =>
    ConfirmModal({ onOk: () => onDelete(id) });

  const onDelete = (id: number | undefined) => {
    setLoading(true);
    CustomService.deleteData(`/broker-templates/${id}`)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          if (data && data.templates.length === 1) {
            handleQueryParam(queryParam);
            handleCancel();
          } else {
            getData();
          }
        },
      });
  };

  const getActionsView = (
    record: ReportTemplateResponse
  ): React.ReactElement => (
    <div
      onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Tooltip title={t('edit')}>
        <Button
          {...actionStyle}
          icon={<EditOutlined />}
          onClick={() => onClickSelectRow(record)}
        />
      </Tooltip>
      {!isApproveTemplate && (
        <Tooltip title={t('button.delete')}>
          <span
            style={{
              pointerEvents: !record.canDelete ? 'none' : 'auto',
            }}
          >
            <DeleteButton
              {...actionStyle}
              className="delete"
              icon={<DeleteOutlined />}
              disabled={!record.canDelete}
              onClick={() => showConfirmModal(record.id)}
            />
          </span>
        </Tooltip>
      )}
    </div>
  );

  const getDefaultColumns = (): ColumnsType<ReportTemplateResponse> =>
    (columns as ColumnsType<ReportTemplateResponse>).map((c) => {
      return { ...c, sorter: false };
    });

  const getColumns = (): ColumnsType<ReportTemplateResponse> => {
    return [
      {
        title: t('version'),
        key: 'index',
        width: '10%',
        render: (text, record, index) => index + 1,
        align: 'center',
      },
      ...getDefaultColumns(),
      {
        title: 'Action',
        width: '9%',
        render: getActionsView,
        align: 'center',
        className: 'last-column',
      },
    ];
  };

  const handleSelectRow = (record: ReportTemplateResponse) => {
    return {
      onClick: () => onClickSelectRow(record),
    };
  };

  const onClickSelectRow = (record: ReportTemplateResponse) => {
    const { id } = record;
    setFormProps({
      ...formProps,
      id,
    });
    showModal();
  };

  const onClickAdd = () => {
    setFormProps({
      ...formProps,
      id: undefined,
    });
    showModal();
  };

  const showAddNewVersion = formProps.id === undefined;

  return (
    <>
      <Row>
        <Col flex="auto">
          <Label>{`${t('templateId')}: ${templateId}`}</Label>
        </Col>
        {!isApproveTemplate && (
          <Col>
            <ButtonAdd
              buttonProps={{ disabled: !data?.canNewVersion }}
              showModal={onClickAdd}
            >
              {t('addNewVersion')}
            </ButtonAdd>
          </Col>
        )}
      </Row>
      <Table
        size="small"
        data-testid="version-report-template-table"
        className="selectable-table"
        columns={getColumns()}
        loading={loading}
        dataSource={data?.templates}
        onRow={handleSelectRow}
        pagination={{
          pageSize: 15,
          showSizeChanger: false,
          showTotal: (total) => t('totalItem', { total }),
        }}
        rowKey={(record) => record.id}
      />
      <ModalCmp
        visible={visible}
        width={700}
        title={
          showAddNewVersion ? t('addNewVersion') : t('editBrokerReportTemplate')
        }
      >
        <ReportTemplateForm {...formProps} />
      </ModalCmp>
      <BtnWrapper>
        <Button
          ghost
          data-testid="close-version-report-template-button"
          type="primary"
          onClick={handleCancel}
          style={{ width: '80px' }}
        >
          {t('button.close')}
        </Button>
      </BtnWrapper>
    </>
  );
};

const DeleteButton = styled(Button)`
  margin-left: 2px;
`;

const BtnWrapper = styled(Space)`
  direction: 'vertical';
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10px;
`;

const Label = styled.div`
  font-weight: 800;
  font-size: 15px;
  margin-top: 10px;
  color: #231f20;
  margin-bottom: 10px;
`;
