import { useAuthContext } from '@contexts/AuthProvider';
import { ApiService } from '@core/services/api.service';
import { Form } from 'antd';
import Button, { ButtonProps } from 'antd/lib/button';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { finalize } from 'rxjs/operators';

export interface ConfirmSendReportViewProps {
  id?: number;
  periodNameView?: React.ReactElement;
  periodDateView?: React.ReactElement;
  statusView?: false | '' | JSX.Element | null;
  handleCancel: () => void;
}

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 10,
  },
};
const SENT_REPORT_ROUTE = '/broker-report-group/sent';
export const ConfirmSendReportView: React.FC<ConfirmSendReportViewProps> = ({
  id,
  periodNameView,
  periodDateView,
  statusView,
  handleCancel,
}) => {
  const { t } = useTranslation(['common']);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const apiService = useMemo(
    () => new ApiService(`/broker-report-group/draft/${id}/sent`),
    []
  );
  const {
    siderMenus,
    roleId,
    defaultMenu: { menuId },
    setDefaultMenu,
  } = useAuthContext();

  const handleSave = () => {
    setLoading(true);
    const result = siderMenus?.menus.find((menu) =>
      menu.subMenus.find((sub) => sub.path === SENT_REPORT_ROUTE)
    );
    const { id: subMenuId } =
      result?.subMenus.find((sub) => sub.path === SENT_REPORT_ROUTE) || {};

    apiService
      .createData({})
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          setLoading(false);
          handleCancel();
          setDefaultMenu({ menuId, subMenuId: [`${subMenuId}`] || [] });
          history.push(
            `/broker-report-group/sent/?menuId=${menuId}&subMenuId=${subMenuId}&roleId=${roleId}`
          );
        },
      });
  };

  const buttonProps: ButtonProps = useMemo(
    () => ({
      onClick: handleSave,
    }),
    [handleSave]
  );

  return (
    <Form
      {...layout}
      className="confirm-send-report-view"
      data-testid="confirm-send-report-view"
      preserve={false}
      onClick={(e) => e.stopPropagation()}
    >
      <Form.Item label={t('reportPeriod')}>{periodNameView}</Form.Item>
      <Form.Item label={t('datePeriod')}>{periodDateView}</Form.Item>
      <Form.Item label={t('status')}>{statusView}</Form.Item>
      <div style={{ marginTop: 24 }}>
        <Button
          style={{ width: '140px' }}
          data-testid="close-confirm-send-button"
          type="primary"
          ghost
          onClick={handleCancel}
        >
          {t('common:button.close')}
        </Button>
        <Button
          loading={loading}
          style={{ width: '140px', float: 'right' }}
          data-testid="confirm-send-button"
          type="primary"
          htmlType="submit"
          {...buttonProps}
        >
          {t('common:confirm')}
        </Button>
      </div>
    </Form>
  );
};
