import { SearchButton } from '@components/SearchButton';
import { useSearchContext } from '@contexts/SearchProvider';
import { ViewDraftReportConfigFormResponse } from '@core/@models/DraftReportModel';
import { CustomService } from '@core/services/custom.service';
import { useSearch } from '@helpers/use-search';
import { filterOption } from '@helpers/utils';
import { Card, Col, DatePicker, Row, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Label, Wrapper } from 'pages/draft-report/DraftReportSearch';

const { RangePicker } = DatePicker;

export interface onRangePickerChange {
  onDateChange?: (values: any, formatString: string) => void;
  onRangeDateChange?: (values: any, formatString: [string, string]) => void;
  onCalendarChange?: (values: any, formatString: [string, string]) => void;
}
type FieldType = 'dropdown' | 'date' | 'rangeDate';

interface SearchFields {
  name: string;
  onChange: ((value: string) => void) | onRangePickerChange;
  value?: string;
  options?: { label: string; value: string }[];
  colFlex: string;
  fieldType: FieldType;
  disabled?: boolean;
}

export const ReportSearch: React.FC = () => {
  const { t } = useTranslation(['common']);
  const {
    queryParam,
    reportStatus,
    menuId,
    roleId,
    searchOptions,
    setSearchOptions,
  } = useSearchContext();

  const {
    date,
    rangeDate,
    statusId,
    templateId,
    dateType,
    companyId,
    handleReset,
    handleSearch,
    handleRangeDateChange,
    handleStatusIdChange,
    handleCalendarChange,
    handleTemplateIdChange,
    handleDateChange,
    handleDateTypeChange,
    handleCompanyIdChange,
  } = useSearch();
  const isSentReport = 'dateType' in queryParam;
  const [showCompanies, setShowCompanies] = useState<boolean>(false);

  useEffect(() => {
    getConfigForm();
  }, [t]);

  const getConfigForm = () => {
    CustomService.getData<ViewDraftReportConfigFormResponse>(
      `broker-report/${reportStatus}/search-form`,
      undefined,
      {
        'x-menu-id': menuId,
        'x-role-id': roleId,
      }
    ).subscribe({
      next: (result: ViewDraftReportConfigFormResponse) => {
        setShowCompanies(result.brokerCompanies != null);
        initialConfigData(result);
      },
    });
  };

  const initialConfigData = (result: ViewDraftReportConfigFormResponse) => {
    const {
      dateTypes: d,
      templateNames: template,
      statuses: s,
      brokerCompanies: bc,
    } = result;
    const templateNames = template.map((t) => ({
      value: `${t.id}`,
      label: t.name,
    }));
    const statuses = s.map((s) => ({ value: s.id, label: s.name }));
    const dateTypes = d?.map((d: string) => ({
      value: d,
      label: t(`${d.toLowerCase()}Date`),
    }));
    const brokerCompanies = bc?.map((b) => ({
      value: `${b.id}`,
      label: b.name,
    }));
    setSearchOptions({ statuses, templateNames, dateTypes, brokerCompanies });
  };

  const getSearchFields = (): SearchFields[] => {
    const draftFields: SearchFields[] = [
      {
        name: 'reportName',
        onChange: handleTemplateIdChange,
        value: templateId,
        options: searchOptions?.templateNames,
        colFlex: isSentReport ? '60' : '50',
        fieldType: 'dropdown',
      },
      {
        name: 'status',
        onChange: handleStatusIdChange,
        value: statusId,
        options: searchOptions?.statuses,
        colFlex: showCompanies ? '40' : '50',
        fieldType: 'dropdown',
        disabled: dateType === 'APPROVE',
      },
    ];
    const sentFields: SearchFields[] = [
      {
        name: 'brokerName',
        onChange: handleCompanyIdChange,
        value: companyId,
        options: searchOptions?.brokerCompanies,
        colFlex: '55',
        fieldType: 'dropdown',
      },
      {
        name: '',
        onChange: (value: string) => {
          handleDateTypeChange(value);
          value === 'APPROVE'
            ? handleStatusIdChange('A')
            : handleStatusIdChange('');
        },
        options: searchOptions?.dateTypes,
        value: dateType,
        colFlex: '40',
        fieldType: 'dropdown',
      },
      {
        name: '',
        onChange: {
          onRangeDateChange: handleRangeDateChange,
          onCalendarChange: handleCalendarChange,
        },
        colFlex: showCompanies ? '70' : '100',
        fieldType: 'rangeDate',
      },
      {
        name: 'dateAsOf',
        onChange: {
          onDateChange: handleDateChange,
        },
        colFlex: showCompanies ? '35' : '50',
        fieldType: 'date',
      },
    ];
    if (isSentReport) {
      return showCompanies
        ? sentFields
            .slice(0, 1)
            .concat(draftFields)
            .concat(sentFields.slice(1, 4))
        : draftFields.concat(sentFields.slice(1, 4));
    }
    return draftFields;
  };

  const getDropdownSearch = (props: SearchFields) => (
    <React.Fragment key={`${props.name}${props.fieldType}`}>
      <Col flex={props.colFlex}>
        <Wrapper>
          {props.name ? <Label>{t(`${props.name}`)}</Label> : <br></br>}
          <CustomSelect
            dropdownMatchSelectWidth={false}
            allowClear
            showSearch
            filterOption={filterOption}
            onChange={props.onChange as (value: SelectValue) => void}
            options={props.options}
            value={props.value || undefined}
            placeholder={`${t('selectAll')}...`}
            disabled={props.disabled}
            style={{ width: '100%' }}
            data-testid={`${props.name || 'dateType'}-search-dropdown`}
          />
        </Wrapper>
      </Col>
    </React.Fragment>
  );

  const getDateSearch = (props: SearchFields) => (
    <React.Fragment key={`${props.name}${props.fieldType}`}>
      <Col flex={props.colFlex}>
        <Wrapper>
          <Label>{t(`${props.name}`)}</Label>
          <DatePicker
            placeholder={t(props.name)}
            value={date}
            onChange={(props.onChange as onRangePickerChange).onDateChange}
            style={{ width: '100%' }}
            data-testid={`${props.name}-search-date-picker`}
          />
        </Wrapper>
      </Col>
    </React.Fragment>
  );

  const getDateRangeSearch = (props: SearchFields) => (
    <React.Fragment key={`${props.name}${props.fieldType}`}>
      <Col flex={props.colFlex}>
        <Wrapper>
          <br></br>
          <RangePicker
            placeholder={[`${t('begin')}...`, `${t('end')}...`]}
            value={rangeDate}
            onChange={(props.onChange as onRangePickerChange).onRangeDateChange}
            onCalendarChange={
              (props.onChange as onRangePickerChange).onCalendarChange
            }
            ranges={{ Today: [moment(), moment()] }}
            style={{ width: '100%' }}
            data-testid={`statusDate-search-range-picker`}
          />
        </Wrapper>
      </Col>
    </React.Fragment>
  );

  return (
    <Card
      title={t('filter')}
      bodyStyle={{ padding: '0px 5px 10px' }}
      className="search-form"
      extra={
        <SearchButton handleSearch={handleSearch} handleReset={handleReset} />
      }
    >
      <Row justify="space-between" className="draft-report-search">
        {getSearchFields().map((s) =>
          s.fieldType === 'rangeDate'
            ? getDateRangeSearch(s)
            : s.fieldType === 'date'
            ? getDateSearch(s)
            : getDropdownSearch(s)
        )}
      </Row>
    </Card>
  );
};

const CustomSelect = styled(Select)`
  width: 100%;
`;
